define([
  'jquery',
  'backbone'
],
function($, Backbone) {
  return {
      getUrlVars: function() {
        /*
         * This utility will parse a URL for easter eggs. Initially, the code will cut off all text prior to the ?
         * So if the URL is http://localhost:9000/uci?test=4&debug=true, the code only cares about test=4&debug=true.
         * Then the code splits this string using & as a delimiter. In my example, the var keyValues will have two elements
         * and look like this test=4, debug=true
         *
         * Now in the for loop we split the expression test=4 into key value pairs. This is useful because when you call
         * the function getUrlVars and can specify a key then just check the corresponding value.
         *
         */
        var elems = [], hash;
        var keyValues = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for(var i = 0; i < keyValues.length; i++) {
            hash = keyValues[i].split('=');
            elems.push(hash[0]);
            elems[hash[0]] = hash[1];
        }
        return elems;
      },
      getUrlVarsWithSplitParem: function(split) {
        /*
         * This utility will parse a URL for easter eggs. Initially, the code will cut off all text prior to the ?
         * So if the URL is http://localhost:9000/uci?test=4&debug=true, the code only cares about test=4&debug=true.
         * Then the code splits this string using & as a delimiter. In my example, the var keyValues will have two elements
         * and look like this test=4, debug=true
         *
         * Now in the for loop we split the expression test=4 into key value pairs. This is useful because when you call
         * the function getUrlVars and can specify a key then just check the corresponding value.
         *
         */ 
        var elems = [], hash;
        var keyValues = window.location.href.slice(window.location.href.indexOf('?') + 1).split(split);
        for(var i = 0; i < keyValues.length; i++) {
            hash = keyValues[i].split('=');
            elems.push(hash[0]);
            elems[hash[0]] = hash[1];
        }
        return elems;
      }
  };
});


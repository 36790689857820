define([
    'jquery',
    'backbone',
    'dna/utilities/infrastructure/ContextLibrary',
    'dna/utilities/infrastructure/UtilityFunctions'
], function($, Backbone, ContextLibrary, UtilityFunctions) {
    var GLOBAL_GET_LIMIT = 500;

    return {
        master_controller_func: function(data) {
            var context, timer_id;
            if (typeof data.context == "undefined") {
                context = ContextLibrary.create_and_set_context({
                    payload: data.context_data
                });
            } else {
                context = data.context;
                var ctxData = ContextLibrary.get_context_data(context);
                data.event_data = ctxData.event_data;
                data.finalCallback = ctxData.finalCallback;
            }
            if (typeof data.timer_id == "undefined") {
                timer_id = null;
                var timer_subcontext = ContextLibrary.create_and_set_subcontext(context, data.timer_subcontext);
            } else {
                timer_id = data.timer_id;
            }
            for (var i = 0; i < data.api_calls.length; i++) {
                for (var j = 0; j < data.api_calls[i].api_data_per_call.length; j++) {
                    if ((data.api_calls[i].feature_enabled == undefined) || (data.api_calls[i].feature_enabled == true)) {
                        data.api_calls[i].api({
                            context: context,
                            subcontext: ContextLibrary.create_and_set_subcontext(context, data.api_calls[i].api_data_per_call[j]),
                            timer_id: timer_id,
                            timer_subcontext: timer_subcontext,
                            finalCallback: data.finalCallback,
                            event_data: data.event_data
                        }, data.api_calls[i].success, data.api_calls[i].failure);
                    }
                }
            }
        },
        create_offset_array: function(length) {
            var offset_arr = [];
            for (var offset = 1; offset <= length; offset += GLOBAL_GET_LIMIT)
                offset_arr.push({
                    offset: offset,
                    limit: GLOBAL_GET_LIMIT
                });
            return offset_arr;
        },
        check_context_list: function(ids, callback, event_data) {
            // delete off the subcontexts as they come back
            ContextLibrary.delete_subcontext(ids.context, ids.subcontext);

            if (ContextLibrary.get_subcontext_length(ids.context) == 1) {
                ContextLibrary.delete_context(ids.context);

                if (typeof callback == 'function') {
                    if (typeof event_data == 'undefined')
                        callback();
                    else
                        callback(event_data);
                }
            }
        },
        decisionToRenderNG: function(event_data) {
            if (typeof event_data.viewCtrlModel != "undefined") {
                event_data.viewCtrlModel.set({"status": UtilityFunctions.generate_uuid()});
            }
        }
    }
});

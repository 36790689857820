define([
  'jquery',
  'backbone',
  'dna/utilities/logging/Logger'
],
function($, Backbone, Logger) {

    // Log module name
    var myLogModuleString = "UCI_UTIL_INFRA_STATE";

    // array of state machines
    var state_machines = [];

    // number of current state machines in the array
    var state_machine_count = 0;

    return {
        create_state_machine: function(machine) {
            var id = state_machine_count;

            // append state machine variables (that we want to be "private") to our machine structure
            machine.Current_state = machine.Init_state;

            // create a stack to handle post requests
            machine.Event_stack = [];

            // create boolean flag to trigger events, 0 means not working and 1 indicates working
            machine.Is_working = 0;

            // add this machine into state machine array
            state_machines[state_machine_count] = machine;

            // update the state machine id
            state_machine_count++;

            // return the id, which is an index into the stateMachine array
            return id;
        },

        post_event: function(machine_id, event_type, event_data) {
            // first, push the event
            state_machines[machine_id].Event_stack.push({event: event_type, data: event_data});

            // if we are not working perform some action
            if (state_machines[machine_id].Is_working == 0) {

                // set is working to true
                state_machines[machine_id].Is_working = 1;

                // now call the handler to do the work
                post_event_handler(machine_id, event_data);

                // we are no longer working
                state_machines[machine_id].Is_working = 0;
            }
        }
    };

    function post_event_handler(machine_id, event_data) {

        while (true) {

            // pop an event from the stack, if the event is undefined the stack is empty
            new_event = state_machines[machine_id].Event_stack.pop();

            // loop until we unravel the stack, if we did, break!
            if (new_event == undefined) {
                break;
            }

            if(typeof state_machines[machine_id].Transitions[state_machines[machine_id].Current_state.id][new_event.event.id].endState == "undefined") {
                // let the user know the transition was illegal from the current state
                Logger.writeLog().error(myLogModuleString, "Unknown event " + new_event.event.name + " in state " + state_machines[machine_id].Current_state.name);
            }
            else {
                Logger.writeLog().trace(myLogModuleString, "Transfered from state [" + state_machines[machine_id].Current_state.name + "] to state [" + state_machines[machine_id].Transitions[state_machines[machine_id].Current_state.id][new_event.event.id].endState.name  +"]" + " by event " + new_event.event.name);
                // launch the action
                Logger.writeLog().trace(myLogModuleString, "Before action");
                state_machines[machine_id].Transitions[state_machines[machine_id].Current_state.id][new_event.event.id].action(new_event.data);
                Logger.writeLog().trace(myLogModuleString, "After action");

                // make the state transition

                state_machines[machine_id].Current_state = state_machines[machine_id].Transitions[state_machines[machine_id].Current_state.id][new_event.event.id].endState;
            }
        }
    }
});
define([
  'jquery',
  'backbone',
  'dna/utilities/logging/Logger',
  'dna/utilities/infrastructure/UtilityFunctions'
],
function($, Backbone, Logger, UtilityFunctions) {

    var myLogModuleString = "UCI_UTIL_INFRA_CTX";

    var context_list = {};

    return {
        /********************************************************************************\
            The context functions are manipulated by passing the uuid to get the context
        \********************************************************************************/
        create_context: function() {
            var uuid = UtilityFunctions.generate_uuid();

            uuid.toString();
            // create a new context

            context_list[uuid] = {
                data: "",
                page_association: "",
                subcontext_list: {},
                subcontext_id_distributor: 0
            };

            // give the caller the uuid
            return uuid;
        },

        create_and_set_context: function(data) {
            var uuid = UtilityFunctions.generate_uuid();
            var page_association;

            // temp code if providing page association becomes standard practice
            if (typeof data.page_association != "undefined")
                page_association = data.page_association;
            else
                page_association = "";

            // create a new context
            context_list[uuid] = {
                data: data.payload,
                page_association: page_association,
                subcontext_list: {},
                subcontext_id_distributor: 0
            };

            // give the caller the uuid
            return uuid;
        },

        set_context_data: function(uuid, data) {
            var page_association;

            // temp code if providing page association becomes standard practice
            if (typeof data.page_association != "undefined")
                page_association = data.page_association;
            else
                page_association = "";

            // set the context data
            context_list[uuid].data = data.payload;
            context_list[uuid].page_association = page_association;
        },

        get_context_data: function(uuid) {
            // check to see if the data exists
            if(context_list[uuid] == undefined)
                return undefined;
            else
                return context_list[uuid].data;
        },

        get_context_page_association: function(uuid) {
            // check to see if the data exists
            if(context_list[uuid] == undefined)
                return undefined;
            else
                return context_list[uuid].page_association;
        },

        delete_context: function(uuid) {
            delete context_list[uuid];
        },

        // check if context list is empty
        is_context_list_empty: function() {
            if (UtilityFunctions.getObjectLength(context_list) == 0)
                return true;
            else
                return false;
        },

        print_all_context_data_to_logger: function() {
            Logger.writeLog().debug("CONTEXT DUMP: " + myLogModuleString, JSON.stringify(context_list));
        },

        print_all_context_data_to_console: function() {
            // console.log("CONTEXT DUMP: " + myLogModuleString, JSON.stringify(context_list));
        },

        /********************************************************************************\
               The subcontext functions are manipulated by passing the uuid to get
             the context and then the subcontext id to get that particular subcontext
        \********************************************************************************/
        create_subcontext: function(uuid) {
            var subcontext_id = context_list[uuid].subcontext_id_distributor;

            // increment the count
            context_list[uuid].subcontext_id_distributor++;

            // initialize the data
            context_list[uuid].subcontext_list[subcontext_id] = {
                subcontext_data: ""
            };

            // give the caller the id back to reference this subcontext
            return subcontext_id;
        },

        create_and_set_subcontext: function(uuid, new_subcontext_data) {
            var subcontext_id = context_list[uuid].subcontext_id_distributor;

            // increment the count
            context_list[uuid].subcontext_id_distributor++;

            // set the data
            context_list[uuid].subcontext_list[subcontext_id] = {
                subcontext_data: new_subcontext_data
            };

            // give the caller the id back to reference this subcontext
            return subcontext_id;
        },

        set_subcontext_data: function(uuid, subcontext_id, data) {
            context_list[uuid].subcontext_list[subcontext_id].subcontext_data = data;
        },

        get_subcontext_data: function(uuid, subcontext_id) {
            // check to see if the data exists
            if(context_list[uuid] == undefined)
                return undefined;
            else if (context_list[uuid].subcontext_list[subcontext_id] == undefined)
                return undefined;
            else
                return context_list[uuid].subcontext_list[subcontext_id].subcontext_data;
        },

        delete_subcontext: function(uuid, subcontext_id) {
            delete context_list[uuid].subcontext_list[subcontext_id];
        },

        // check subcontext list for empty
        is_subcontext_list_empty: function(uuid) {
            if(context_list[uuid] == undefined)
                return undefined;
            else if (UtilityFunctions.getObjectLength(context_list[uuid].subcontext_list) == 0)
                return true;
            else
                return false;
        },

        get_subcontext_length: function(uuid) {
            if(context_list[uuid] == undefined)
                return undefined;
            else
                return UtilityFunctions.getObjectLength(context_list[uuid].subcontext_list);
        }
    };
});
var amdi18n={"__root":{"errorhandler_unknown_error":"An unknown error has occured.","errorhandler_invalid_token":"Invalid CSRF Token"},"__en":{"errorhandler_unknown_error":"An unknown error has occured.","errorhandler_invalid_token":"Invalid CSRF Token"},"__ja":{"errorhandler_unknown_error":"未知のエラーが発生しました。","errorhandler_invalid_token":"無効なCSRFトークンです"},"__zh":{"errorhandler_unknown_error":"发生未知错误。","errorhandler_invalid_token":"CSRF 令牌无效"},"__ko":{"errorhandler_unknown_error":"알 수 없는 오류가 발생했습니다.","errorhandler_invalid_token":"잘못된 CSRF 토큰"}};amdi18n.init=function(language){
		// get the default language
		if(!language){
			if(window._i18n && window._i18n.locale){
				language = window._i18n.locale;
			}else if(document.documentElement.lang){
				language = document.documentElement.lang;
			}else{
				language = 'root';
			}
		}
		var target = this['__' + language] || this.__root;

		// copy definition to root level
		if (target) {
			for (var name in target) {
				this[name] = target[name];
			}
		}

		// fallback to root
		for(var name in this.__root){
			if(typeof this[name] === 'undefined'){
				this[name] = this.__root[name];
			}
		}
	};amdi18n.init();module.exports=amdi18n;
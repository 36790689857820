export default {
  "maintenance_in_progress": "유지 관리 진행 중",
  "restoring_backup": "백업 복원 중",
  "system_scale_in_progress": "시스템 확장 진행 중",
  "system_update_in_progress": "시스템 업데이트 진행 중",
  "checking_system_update": "시스템 업데이트 상태 확인 중...",
  "checking_maintenance_mode": "유지 관리 모드 상태 확인 중...",
  "seconds": "초",
  "minutes": "분",
  "took": "걸림",
  "complete": "완료",
  "of": "/",
  "completed": "작업 완료됨",
  "hide": "상세정보 숨기기",
  "show": "세부사항 표시",
  "phase": "단계",
  "please": "업그레이드가 완료되면 Cisco DNA Center로 리디렉션됩니다."

};

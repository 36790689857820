define({
    "root": {
        "errorhandler_unknown_error":"An unknown error has occured.",
        "errorhandler_invalid_token": "Invalid CSRF Token"
    },
    "en":true,
    "ja":true,
    "zh":true,
    "ko":true
});

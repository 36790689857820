var amdi18n={"__root":{"timermessage1":"You have been inactive for too long.","timermessage2":"For security purposes, you are about to be logged out automatically.","timermessage":"{{minutes}} min, {{seconds}} sec remaining...","timermessage_showSeconds":"{{seconds}} sec remaining...","timeoutwarning":"Session Timeout","endsession":"Sign out","extendsession":"Stay signed in"},"__ja":{"timermessage1":"操作していない状態が続いています。","timermessage2":"セキュリティのため、間もなく自動的にログアウトされます。","timermessage":"残り時間は{{minutes}}分、{{seconds}}秒です...","timermessage_showSeconds":"{{seconds}}秒残っています...","timeoutwarning":"セッションタイムアウト","endsession":"ログアウト","extendsession":"サインインしたままにする"},"__zh":{"timermessage1":"无活动时间过长.","timermessage2":"出于安全原因，您将自动登出。","timermessage":"{{minutes}} 分 {{seconds}} 秒（剩余）...","timermessage_showSeconds":"{{seconds}} 秒（剩余）...","timeoutwarning":"会话超时","endsession":"退出","extendsession":"保持登录状态"},"__ko":{"timermessage1":"오랫동안 비활성 상태였습니다.","timermessage2":"보안을 위해 자동으로 로그아웃됩니다.","timermessage":"{{minutes}}분, {{seconds}}초 남음...","timermessage_showSeconds":"{{seconds}}초 남음...","timeoutwarning":"세션 시간 초과","endsession":"로그아웃","extendsession":"로그인 상태 유지"}};amdi18n.init=function(language){
		// get the default language
		if(!language){
			if(window._i18n && window._i18n.locale){
				language = window._i18n.locale;
			}else if(document.documentElement.lang){
				language = document.documentElement.lang;
			}else{
				language = 'root';
			}
		}
		var target = this['__' + language] || this.__root;

		// copy definition to root level
		if (target) {
			for (var name in target) {
				this[name] = target[name];
			}
		}

		// fallback to root
		for(var name in this.__root){
			if(typeof this[name] === 'undefined'){
				this[name] = this.__root[name];
			}
		}
	};amdi18n.init();module.exports=amdi18n;
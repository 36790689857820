export default {
  "maintenance_in_progress": "メンテナンスが進行中",
  "restoring_backup": "バックアップの復元中",
  "system_scale_in_progress": "システムスケールが進行中",
  "system_update_in_progress": "システム更新が進行中",
  "checking_system_update": "システム更新ステータスを確認しています...",
  "checking_maintenance_mode": "メンテナンスモードのステータスを確認中...",
  "seconds": "秒",
  "minutes": "分",
  "took": "所要時間",
  "complete": "完了",
  "of": "/",
  "completed": "タスクが完了しました",
  "hide": "詳細情報の非表示",
  "show": "詳細を表示する",
  "phase": "フェーズ",
  "please": "アップグレードが完了すると、Cisco DNA Centerにリダイレクトされます。"

};

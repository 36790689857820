define([
  'jquery'
], function($) {
  "use strict";
  return {
    getDiscoveryStatus: function(formData) {
      var dfd = new $.Deferred();

      $.ajax({
        url: API_URL_PREFIX + "/telemetry/dashboardstats/discovery_status",
        type: 'GET',
        success: function(data) {
          dfd.resolve(data.response);
        },
        error: function(data) {
          dfd.reject(data);
        }
      });

      return dfd.promise();
    },
    gethostTypes: function() {
      var dfd = new $.Deferred();

      $.ajax({
        url: API_URL_PREFIX + "/telemetry/dashboardstats/host_type",
        type: 'GET',
        success: function(data) {
          dfd.resolve(data.response);
        },
        error: function(data) {
          dfd.reject(data);
        }
      });

      return dfd.promise();
    },
    getPolicyApplications: function(formData) {
      var dfd = new $.Deferred();

      $.ajax({
        url: API_URL_PREFIX + "/telemetry/dashboardstats/policy_applicationtype",
        type: 'GET',
        success: function(data) {
          dfd.resolve(data.response);
        },
        error: function(data) {
          dfd.reject(data);
        }
      });

      return dfd.promise();
    },
    getPolicyScopes: function(formData) {
      var dfd = new $.Deferred();

      $.ajax({
        url: API_URL_PREFIX + "/telemetry/dashboardstats/policy_scope_status",
        type: 'GET',
        success: function(data) {
          dfd.resolve(data.response);
        },
        error: function(data) {
          dfd.reject(data);
        }
      });

      return dfd.promise();
    },
    getInventoryStats: function(){
      var dfd = new $.Deferred();

      $.ajax({
        url: API_URL_PREFIX + "/telemetry/dashboardstats/inventory_reachability_failures",
        type: 'GET',
        success: function(data) {
          dfd.resolve(data.response);
        },
        error: function(data) {
          dfd.reject(data);
        }
      });

      return dfd.promise();
    }

  };
});

var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class='dropdown-menu dropdown-menu-right settings'>\n	<li class='dropdown-header apic-em menu-system'>"
    + alias4(((helper = (helper = lookupProperty(helpers,"system") || (depth0 != null ? lookupProperty(depth0,"system") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"system","hash":{},"data":data,"loc":{"start":{"line":2,"column":49},"end":{"line":2,"column":59}}}) : helper)))
    + "</li>\n	<li class='dropdown-header menu-help'>"
    + alias4(((helper = (helper = lookupProperty(helpers,"help") || (depth0 != null ? lookupProperty(depth0,"help") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"help","hash":{},"data":data,"loc":{"start":{"line":3,"column":39},"end":{"line":3,"column":47}}}) : helper)))
    + "</li>\n	<li><a href=\"https://developer.cisco.com/site/dna-center-rest-api/?version=1.2.x\" target=\"_blank\" class=\"_api documentation\"><span>API Documentation</span></a> </li>\n	<li>\n		<a href=\"https://developer.cisco.com/site/dna-center\" target=\"_blank\"><span class='_dev-resources'>"
    + alias4(((helper = (helper = lookupProperty(helpers,"developer_resource") || (depth0 != null ? lookupProperty(depth0,"developer_resource") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"developer_resource","hash":{},"data":data,"loc":{"start":{"line":6,"column":101},"end":{"line":6,"column":123}}}) : helper)))
    + "</span></a>\n	</li>\n	<li class='_showAbout'>\n		<a><span class='_logs'>"
    + alias4(((helper = (helper = lookupProperty(helpers,"about_dna_center") || (depth0 != null ? lookupProperty(depth0,"about_dna_center") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"about_dna_center","hash":{},"data":data,"loc":{"start":{"line":9,"column":25},"end":{"line":9,"column":45}}}) : helper)))
    + "</span></a>\n	</li>\n	<!--<li class='dropdown-header menu-user'>User</li>\n	<li class='_changePasswordHolder'>\n		<a href=\"/dna/systemSettings/settings?settings-item=changePassword\">\n			<span class='_changePassword'>Change Password</span>\n		</a>\n	</li>-->\n	<li role='separator' class='divider'></li>\n	<li class='username'></li>\n	<li class='_signoutHolder'>\n		<a>\n			<span class='_signout'>"
    + alias4(((helper = (helper = lookupProperty(helpers,"sign_out") || (depth0 != null ? lookupProperty(depth0,"sign_out") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sign_out","hash":{},"data":data,"loc":{"start":{"line":21,"column":26},"end":{"line":21,"column":38}}}) : helper)))
    + "</span>\n		</a>\n	</li>\n</ul>";
},"useData":true});
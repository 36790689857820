import nls from "i18n!./nls/i18n_login";
import i18nUtil from "core/utils/i18n";

import {
  isSupportedBrowser,
  isSupportedVersion,
  getBrowserInfo
} from "core/utils/SupportedBrowser";

/**
 * If a user uses a browser that isn't in our supported browser list,
 * we can kindly let them know in case they run into any issues
 */
export default () => {
  const warn_msg_incmpltbl_version = i18nUtil.getFilledTextForHBS(
    nls.warn_msg_incmpltbl_version,
    getBrowserInfo()
  );

  if (!isSupportedBrowser()) {
    return nls.warn_msg_browser_incmptbl;
  }

  if (!isSupportedVersion()) {
    return warn_msg_incmpltbl_version;
  }

  return "";
}
define([
  'jquery'
], function($) {
  return {
    setPreference: function(key, value) {
      $.ajax({
        url: "/session/setPreference",
        type: "POST",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({"key": key, "value": value})
      });
    },
    getPreference: function(key) {
      var dfd = new $.Deferred();
      $.ajax({
        url: "/session/getPreference",
        type: "POST",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({"key": key}),
        success: function(data) {
          dfd.resolve(data);
        }
      });
      return dfd.promise();
    }
  };
});
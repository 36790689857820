/***************************************************

   Broadcast
   apicem/utils/broadcast

   A simple Publish/Subscriber class to make module communication
   easier


   Usage:
   -------------------
   var subscription = {
      "action1": function(data) {
            data.addClass('hide');
      },
      "action2": function() {
            _action2();
      }
   };
   Broadcast.subscribe(subscription);

   // send message
   Broadcast.push("action1");

   // Check subscription exists
   var subscription =  {action1": function(data) {
       console.log("action1");
   }}
   Broadcast.isSubscriptionExist(subscription);

   //Remove subscription
   Broadcast.removeSubscriptions(["action1", "action2"]);

****************************************************/

define([], function() {
  var subscriptions = [];

  return {
    subscribe: function(subscription) {
      subscriptions.push(subscription);
    },
    push: function(msg, data) {
      // data is optional, just there if there's some relevant
      // info that needs to be passed (like an id or something)
      subscriptions.map(function(n) {
        if (n[msg]) {
          n[msg](data);
        }
      });
    },

    isSubscriptionExist: function(topic /*Object*/) {
      var subscriptionExist = false,
        count,
        topicKey,
        topicValue,
        subscriptionKey,
        subscriptionValue;

      for (count = 0; count < subscriptions.length; count++) {
        topicKey = Object.keys(topic)[0];
        subscriptionKey = Object.keys(subscriptions[count])[0];
        topicValue = topic[topicKey].toString();
        subscriptionValue = subscriptions[count][subscriptionKey].toString();
        if (topicKey === subscriptionKey && topicValue === subscriptionValue) {
          subscriptionExist = true;
          break;
        }
      }
      return subscriptionExist;
    },

    removeSubscriptions: function(topics /*Array*/) {
      var count, subscriptionsCount;
      for (count = 0; count < topics.length; count++) {
        for (
          subscriptionsCount = 0;
          subscriptionsCount < subscriptions.length;
          subscriptionsCount++
        ) {
          if (topics[count] === Object.keys(subscriptions[subscriptionsCount])[0]) {
            subscriptions.splice(subscriptionsCount, 1);
            //Not breaking the inner loop since some of subscription key is used more than once with different callback function
          }
        }
      }
    }
  };
});

/* Toolbar Selector */
define([
  "jquery"
], function($) {
  return function(id) {
    this.id = id;
    var elem = this.elem = $(".toolbar-selector[data-toolbar-id='"+id+"']");
    this.popout = elem.find(".popout");

    var self = this;

    /* Open close dropdown */
    elem.find(".widget")
      .click(function(e) {
        e.stopPropagation();
        self.toggleDropdown();
      });
    
    $('body').click(function(e){
      self.closeDropdown();
    });

    /* Options */
    this.popout.find(".option")
      .click(function(e) {
        e.stopPropagation();
        self.selectView(this);
      });

    this.triggerSelect = function(opt) {
      var selected = elem.find(".option[data-option-name='"+opt+"']");
      self.setView(opt);
      self.onSelect(opt);
    };
    this.selectView = function(that){
        var selected = $(that).attr("data-option-name");
        self.setView(selected);
        self.closeDropdown();
    };
    this.setView = function(option) {
      this.setTitle(option);
      self.onSelect(option);
    };
    this.closeDropdown = function() {
      this.elem.removeClass("open");
    }
    this.toggleDropdown = function() {
      this.elem.toggleClass("open");
    };

    this.setTitle = function(title) {
      this.elem.find(".widget .selected")
        .text(title);
    };
    this.getTitle = function() {
      return this.elem.find(".widget .selected").text();
    };

    /* Modify dropdown */
    this.prepend = function(option) {
      var self = this;
      var opt = $("<div>")
        .addClass("option")
        .attr("data-option-name", option)
        .text(option);

      opt.prependTo(self.elem.find(".popout"));
    };
    this.append = function(option) {
      var self = this;
      var opt = $("<div>")
        .addClass("option")
        .attr("data-option-name", option)
        .text(option);

      opt.appendTo(self.elem.find(".popout"))
        .click(function(e){
          e.stopPropagation();
          self.selectView(this);
        });
    };
    this.remove = function(option) {
      var opt = this.elem
        .find(".option[data-option-name='"+option+"']");
      opt.remove();
    };
  };
});


const platform = require("../harbor-platform").platform;

// Standard functions of the wrapper
define("core/utils/wrapper", [
  "./polykills",
  "jquery",
  "core/widgets/login/index",
  "core/widgets/header/navMenu",
  "core/utils/auth",
  "core/utils/DurationTrack",
  "core/utils/sessionTimeout/IdleSessionTimeout",
  "core/apicem/utils/localStorage",
  "core/plugin/startup",
  "core/utils/rbac",
  "core/utils/axios",
  "i18n!./nls/i18_wrapper",
  "dnx-web-components/dist/index-with-deps-react.min"
], function(
  polykills,
  $,
  login,
  settingsMenu,
  auth,
  DurationTrack,
  IdleSessionTimeout,
  localStorage,
  pluginStartup,
  rbac,
  axios,
  nls
) {
  const AUTH_POLL_TIME_MS = 40000;
  const _dnxToast = $("<dnx-toast />").appendTo("body")[0];

  function checkGracePeriod() {
    const pwdExpiryValues = localStorage.getItem("x-password-expiry-day");
    if (pwdExpiryValues !== null) {
      const passwordExpiryArray = pwdExpiryValues.split(",").map(Number);
      const passwordExpiry = passwordExpiryArray[0];
      const passwordGracePeriod = passwordExpiryArray[2];

      if (passwordExpiry < 0 && Math.abs(passwordExpiry) <= passwordGracePeriod) {
        return true;
      }
    }
    return false;
  }

  function initLoggedIn() {
    if (!window.appcontext.authSkip) {
      scheduleCheckAuthenticated(); // start auth polling
    }

    if (checkGracePeriod() && location.pathname !== "/passwordReset") {
      location.pathname = "/passwordReset";
    } else if (!checkGracePeriod() && location.pathname === "/passwordReset") {
      location.pathname = "/";
    }

    const dfd = new $.Deferred();
    const rbacPromise = rbac.init();
    platform
      .init()
      .then(() =>
        rbacPromise.catch(() => {
          if (!window.appcontext.authSkip) {
            _dnxToast.toast({
              message: nls.intentsError,
              flavor: "warning"
            });
          }
        })
      )
      .then(settingsMenu.init)
      .then(DurationTrack.init)
      .then(IdleSessionTimeout.init())
      .then(pluginStartup)
      .then(() => {
        dfd.resolve();
      });
    return dfd.promise();
  }

  function initLoggedOut() {
    platform.init().then(() => {
      login.init("#apicloginBox");
    });
  }

  function scheduleCheckAuthenticated() {
    setTimeout(checkAuthenticated, AUTH_POLL_TIME_MS);
  }

  /**
   * Periodically poll and check if session is still active
   * otherwise, force-sign-out the user
   */
  function checkAuthenticated() {
    axios
      .get(
        `/api/system/v1/identitymgmt/users/?authSource=internal&limit=-1&username=${window.USERNAME}`,
        { poller: true }
      )
      .then(scheduleCheckAuthenticated)
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          auth.signout();
        } else {
          scheduleCheckAuthenticated();
        }
      });
  }

  return {
    init: function() {
      window.RELEASE_NAME = localStorage.getItem("releaseName");
      window.RELEASE_VERSION = localStorage.getItem("releaseVersion");
      if (window.USERNAME) {
        return initLoggedIn();
      }
      initLoggedOut();
    }
  };
});

//
// Client side plugin kernel.
//
// Responsibilities:
//
// (1) Manages of "dev" plugins.  Dev plugins are remote plugins
// that are visible only on the client side. Remote plugins are
// plugins that are not bundled with the platform; they are instead
// identified by a url to their plugin manifest (i.e. ui-config.json).
//
// (2) Builds and maintains a plugin list & index for lookup of
// plugin metadata (e.g. the set of extension points & contributions).
//

const platform = require("../harbor-platform").platform;
const pluginRegistry = platform.pluginRegistry;

// RequireJS exports for backward compatibility
define(function() {
  return {
    getPluginConfigs: pluginRegistry.getPluginConfigs.bind(pluginRegistry),
    getContributionIndex: pluginRegistry.getPluginConfigs.bind(pluginRegistry),
    addDevPlugin: pluginRegistry.addDevPlugin.bind(pluginRegistry),
    removeDevPlugin: pluginRegistry.removeDevPlugin.bind(pluginRegistry),
    clearDevPlugins: pluginRegistry.clearDevPlugins.bind(pluginRegistry),
    init: pluginRegistry.init.bind(pluginRegistry)
  };
});

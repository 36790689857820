define(function(require) {
  const PluginContributions = require("core/utils/PluginContributions");
  const parseReference = require("core/utils/parseReference");

  const viewContribMap = (function() {
    let map;
    return function() {
      if (!map) {
        map = PluginContributions.forQualifiedExtensionPoint(
          "cisco.dna.core/view"
        ).reduce((m, entry) => {
          const id = `${entry.sourcePluginId}/${entry.data.name}`;
          if (m[id]) {
            console.error("Found more than one view registered with id %s", id);
          }
          m[id] = entry;
          return m;
        }, {});
      }
      return map;
    };
  })();

  function getViewContrib(viewIdStr, requestingPluginId) {
    const {pluginId, name} = parseReference(viewIdStr, requestingPluginId);
    return viewContribMap()[`${pluginId}/${name}`];
  }

  function viewExists(viewIdStr, requestingPluginId) {
    return !!getViewContrib(viewIdStr, requestingPluginId);
  }

  /**
   * Return a promise that resolves to a view factory for the view with the registered id
   * @param  {string} viewIdStr   id of the plugin that provides the view factory
   * @return {Promise}           a Promise that resolves to the view factory, or null if not found
   */
  function getView(viewIdStr, requestingPluginId) {
    const contrib = getViewContrib(viewIdStr, requestingPluginId);
    if (!contrib) {
      throw new Error(`View with id ${viewIdStr} not found`);
    }
    return viewFromContribution(contrib);
  }

  function viewFromContribution(contrib, contribData = {}) {
    const {module, viewRef} = contribData;
    const moduleValue = module || contrib.data.module;
    const viewRefValue = viewRef || contrib.data.viewRef;
    return moduleValue
      ? contrib.loadModule(moduleValue)
      : viewRefValue
        ? viewFromViewRef(viewRefValue, contrib)
        : (() => {
          console.error("Invalid contrib passed to viewFromContribution:", contrib);
          throw new Error("Contribution does not have a module or viewRef property");
        })();
  }

  function viewFromViewRef({viewId, config}, sourceContrib) {
    const view = getView(viewId, sourceContrib && sourceContrib.sourcePluginId);
    const finalProps = Object.assign({}, config, {contribution: sourceContrib});
    return view ? view.then(viewWithInitialProps(finalProps)) : Promise.resolve(null);
  }

  function viewWithInitialProps(initialProps) {
    return function(viewFactory) {
      return function() {
        const vf = viewFactory();
        return {
          getDOMNode(props) {
            const newProps = Object.assign({}, initialProps, props);
            return vf.getDOMNode(newProps);
          },
          onRender() {
            if (vf.onRender) {
              return vf.onRender();
            }
          },
          dispose() {
            return vf.dispose();
          }
        };
      };
    };
  }

  return {
    viewExists,
    getView,
    viewFromContribution,
    viewFromViewRef,
    viewWithInitialProps
  };
});

const backupRestoreTasks = {
    "RESTORE.ENABLE_MAINTENENCE_MODE": "Enabling maintenance mode",
    "RESTORE.PREPARE_RESTORE": "Preparing restoration",
    "RESTORE.PREPARE_RESTORE.fusion:maintenance-service": "Restoring fusion:maintenance-service",
    "RESTORE.fusion:postgres": "Restoring fusion:postgres",
    "RESTORE.maglev-system:glusterfs-server": "Restoring maglev-system:glusterfs-server",
    "RESTORE.maglev-system:credentialmanager": "Restoring maglev-system:credentialmanager",
    "RESTORE.maglev-system:mongodb": "Restoring maglev-system:mongodb",
    "RESTORE.POST_RESTORE.fusion:maintenance-service": "Post restoring fusion:maintenance-service",
    "RESTORE.POST_RESTORE": "Post restoration",
    "RESTORE.TRIGGER_SERVICES_RESTART": "Triggering services restart",
    "RESTORE.MONITOR_SERVICES_RESTART": "Monitoring services restart",
    "RESTORE.FINALIZE_RESTORE.fusion:maintenance-service": "Finalize restoration",
    "RESTORE.DISABLE_MAINTENENCE_MODE": "Disabling maintenance mode"
};

export {
    backupRestoreTasks
}
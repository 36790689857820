const platform = require("../harbor-platform").platform;

const rbac = platform.rbac;

const STORAGE_KEY = "cisco.dna.core.intents";

module.exports = {
  getPermittedOperations: rbac.getPermittedOperations.bind(rbac),
  hasResource: rbac.hasResource.bind(rbac),
  hasPermission: rbac.hasPermission.bind(rbac),
  init: rbac.loadPemissions.bind(rbac),
  clearCached() {
    localStorage.removeItem(STORAGE_KEY);
  }
};

const platform = require("../harbor-platform").platform;

define(function(require) {
  const PluginContributions = require("core/utils/PluginContributions");
  // const whitelist = ["cisco.dna.core.shell"];

  //
  // Load & run startup code from all plugins contributing to the "startup"
  // extension point of "cisco.dna.core".
  //
  function loadStartupPlugins() {
    const startupPlugins = new PluginContributions("cisco.dna.core").forExtensionPoint(
      "startup"
    );
    // .filter(p => whitelist.indexOf(p.sourcePluginId) !== -1);
    if (startupPlugins.length) {
      startupPlugins.forEach(entry => entry.loadModule());
    } else {
      console.info("No startup plugins found...");
    }
  }

  return function() {
    return platform.init().then(loadStartupPlugins);
  };
});

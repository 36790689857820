define({
  "root":{
    "system": "System",
    "help": "Help",
    "about_dna_center": "About {{productName}}",
    "sign_out": "Sign Out",
    "developer_resource": "Developer Resources",
    "tools": "Tools"
  },
  "en":true,
  "ja":true,
  "zh":true,
  "ko":true
});

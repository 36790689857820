define([
    'jquery',
    'backbone',
    'log4javascript',
    'dna/utilities/browser/parseUrl'
],
function($, Backbone, Log4Javascript, ParseUrl) {
    var log = Log4Javascript.getLogger();
    var popUpAppender = new Log4Javascript.PopUpAppender();
    var commonCallbackData = undefined;
    var commonCallbackFunction = undefined;
    return {
        initLog: function(callbackData, callbackFunction) {
            'use strict';

            // the false parameter sete LazyInit to false (popUpWindow appears immediately)
            if(ParseUrl.getUrlVars()["debug"] == "true") {
                log.addAppender(popUpAppender);
                // associate the popUpAppender to the log instance
                switch (ParseUrl.getUrlVars()["debuglevel"]) {
                    case "all":
                        log.setLevel(Log4Javascript.Level.ALL);
                        break;
                    case "trace":
                        log.setLevel(Log4Javascript.Level.TRACE);
                        break;
                    case "debug":
                        log.setLevel(Log4Javascript.Level.DEBUG);
                        break;
                    case "info":
                        log.setLevel(Log4Javascript.Level.INFO);
                        break;
                    case "warn":
                        log.setLevel(Log4Javascript.Level.WARN);
                        break;
                    case "error":
                        log.setLevel(Log4Javascript.Level.ERROR);
                        break;
                    case "fatal":
                        log.setLevel(Log4Javascript.Level.FATAL);
                        break;
                    default:
                        log.setLevel(Log4Javascript.Level.ERROR);
                }
            }
            if (callbackData != undefined) {
                commonCallbackData = callbackData;
            }
            if (callbackFunction != undefined) {
                commonCallbackFunction = callbackFunction;
            }            
        },
        installCommonCallback: function(callbackData, callbackFunction) {
            commonCallbackData = callbackData;
            commonCallbackFunction = callbackFunction;
        },
        /*** Create new instance of PopUpAppender ***/
        addLog: function(){
            if(ParseUrl.getUrlVars()['debug'] == 'true'){
                log = Log4Javascript.getLogger('main');
                popUpAppender = new Log4Javascript.PopUpAppender();
                if(!popUpAppender.isVisible()){
                    log.addAppender(popUpAppender);
                }
            }        
        },
        writeLog: function() {
            return {
                trace: function(moduleName, logObj, callbackData, callbackFunction) {
                    log.trace(moduleName.concat(":", logObj));
                    if ((commonCallbackData != undefined && (commonCallbackFunction != undefined))) {
                        commonCallbackFunction(commonCallbackData, Log4Javascript.Level.TRACE, moduleName, logObj);
                    }
                    if ((callbackData != undefined && (callbackFunction != undefined))) {
                        callbackFunction(callbackData, Log4Javascript.Level.TRACE, moduleName, logObj);
                    }
                },
                debug: function(moduleName, logObj, callbackData, callbackFunction) {
                    log.debug(moduleName.concat(":", logObj));                   
                    if ((commonCallbackData != undefined && (commonCallbackFunction != undefined))) {
                        commonCallbackFunction(commonCallbackData, Log4Javascript.Level.DEBUG, moduleName, logObj);
                    }
                    if ((callbackData != undefined && (callbackFunction != undefined))) {
                        callbackFunction(callbackData, Log4Javascript.Level.DEBUG, moduleName, logObj);
                    }
                },
                info: function(moduleName, logObj, callbackData, callbackFunction) {
                    log.info(moduleName.concat(":", logObj));
                    if ((commonCallbackData != undefined && (commonCallbackFunction != undefined))) {
                        commonCallbackFunction(commonCallbackData, Log4Javascript.Level.INFO, moduleName, logObj);
                    }
                    if ((callbackData != undefined && (callbackFunction != undefined))) {
                        callbackFunction(callbackData, Log4Javascript.Level.INFO, moduleName, logObj);
                    }
                },
                warn: function(moduleName, logObj, callbackData, callbackFunction) {
                    log.warn(moduleName.concat(":", logObj));
                    if ((commonCallbackData != undefined && (commonCallbackFunction != undefined))) {
                        commonCallbackFunction(commonCallbackData, Log4Javascript.Level.WARN, moduleName, logObj);
                    }
                    if ((callbackData != undefined && (callbackFunction != undefined))) {
                        callbackFunction(callbackData, Log4Javascript.Level.WARN, moduleName, logObj);
                    }
                },
                error: function(moduleName, logObj, callbackData, callbackFunction) {
                    log.error(moduleName.concat(":", logObj));
                    if ((commonCallbackData != undefined && (commonCallbackFunction != undefined))) {
                        commonCallbackFunction(commonCallbackData, Log4Javascript.Level.ERROR, moduleName, logObj);
                    }
                    if ((callbackData != undefined && (callbackFunction != undefined))) {
                        callbackFunction(callbackData, Log4Javascript.Level.ERROR, moduleName, logObj);
                    }
                },
                fatal: function(moduleName, logObj, callbackData, callbackFunction) {
                    log.fatal(moduleName.concat(":", logObj));
                    if ((commonCallbackData != undefined && (commonCallbackFunction != undefined))) {
                        commonCallbackFunction(commonCallbackData, Log4Javascript.Level.FATAL, moduleName, logObj);
                    }
                    if ((callbackData != undefined && (callbackFunction != undefined))) {
                        callbackFunction(callbackData, Log4Javascript.Level.FATAL, moduleName, logObj);
                    }
                }
            };
        }
    };
});

import nls from "i18n!./nls/i18n_login";

/**
 * Return informative errors based on the status code
 */
export default (statusCode, error) => {
  switch (statusCode) {
  case 400: return nls.error_msg_unable_to_login;
  case 401: return nls.error_msg_invalid_credentials;

  case 403: {
    if (error === "Password has expired. Please contact your administrator.") {
      return nls.error_msg_pwd_exp;
    } else if (error.message === "Your IP address is not allowed"){
      return nls.msg_access_denied;
    }else{
      return nls.error_msg_invalid_csrf;
    }
  }

  case 404: return nls.error_msg_unable_to_login;
  case 429: return error;

  case 500: return nls.error_msg_unable_to_login;
  case 501: return nls.error_msg_unable_to_login;
  case 503: return nls.error_msg_unable_to_login;

  default: {
    if (error.response && error.response.message) {
      return error.response.message;
    } else {
      return statusCode + ":" + error;
    }
  }
  }
}
export default {
  root: {
    timermessage1: "You have been inactive for too long.",
    timermessage2: "For security purposes, you are about to be logged out automatically.",
    timermessage: "{{minutes}} min, {{seconds}} sec remaining...",
    timermessage_showSeconds: "{{seconds}} sec remaining...",
    timeoutwarning: "Session Timeout",
    endsession: "Sign out",
    extendsession: "Stay signed in"
  },
  ja: true,
  zh: true,
  ko: true
};

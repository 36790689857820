import flatten from "lodash/flatten";
import qualifiedId from "core/utils/qualifiedId";

/**
 * @type {Object.<string, import("@harbor/platform/Filter").PlatformFilterResolver<unknown>>}
 */
export const filterResolvers = {
  maintenanceMode(value, { isInternal }, apply, onError) {
    if (!isInternal && value !== undefined) {
      onError("maintenanceMode");
    }
    return (isInternal && value) || window.appcontext.maintenanceStatus === null;
  },
  isCloud(value) {
    const isCloud = window.appcontext.cloudProvider !== "none";
    return typeof value !== "boolean" || isCloud === value;
  },
  isAddressFamily(value) {
    return window.appcontext.addressFamily === value;
  },
  isFipsEnabled(value) {
    return window.appcontext.fipsEnabled === value;
  },
  cloudProvider(value) {
    const values = flatten([value]);
    return values.some(v => v === window.appcontext.cloudProvider);
  },
  appRole(value) {
    return (
      typeof value !== "string" || window.appcontext.appRole === value.toLowerCase()
    );
  },
  featureFlag(flagId, { sourcePluginId }) {
    const featureFlags = require("core/plugin/featureFlags");
    return featureFlags.isEnabled(qualifiedId(flagId, sourcePluginId));
  }
};

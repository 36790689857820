define({
  root: {
    learn: "Learn",
    api_reference: "API Reference",
    system: "System",
    help: "Help",
    about_dna_center: "About",
    sign_out: "Sign Out",
    developer_resource: "Developer Resources",
    tools: "Tools",
    make_a_wish: "Make a Wish",
    audit_logs: "Audit Logs",
    system_settings: "System Settings",
    contact_support: "Contact Support"
  },
  en: true,
  ja: true,
  zh: true,
  ko: true
});

/***************************************************

  Download util
  apicem/utils/localStorage.

  Allows for simple manipulation of localStorage.

  Notes:
  -------------------
  A page session lasts for as long as the browser is open and survives over page reloads and restores.
  Opening a page in a new tab or window will cause a new session to be initiated.


  Important Note:
  -------------------
  We clears localStorage.data on signout


  Usage:
  -------------------
  localStorage.setItem(key, value);
  localStorage.getItem("key");
  localStorage.removeItem("key");
  localStorage.clear();

****************************************************/

define([], function() {
  function _remove(key) {
    localStorage.removeItem(key);
  }
  function _get(key) {
    var value = localStorage.getItem(key);
    if (value instanceof Object) {
      return JSON.parse(value);
    }
    return value;
  }
  function _set(key, value) {
    if (value instanceof Object) {
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
  }
  function _clear() {
    localStorage.clear();
  }

  return {
    /**
     * When passed a key name, will remove that key from the storage.
     * @method  localStorage.removeItem
     * @param {string} key
     */
    removeItem: function(key) {
      _remove(key);
    },
    /**
     * When passed a key name, will return that key's value.
     * @method  localStorage.getItem
     * @param {string} key
     *   @return string or Obj value of the key
     */
    getItem: function(key) {
      return _get(key);
    },
    /**
    * When passed a key name and value, will add that key to the storage,
    or update that key's value if it already exists.
    * @method  localStorage.setItem
    * @param {string} key
    * @param {string or obj} value
    */
    setItem: function(key, value) {
      _set(key, value);
    },

    /** When invoked, will empty all keys out of the storage.
     * @method  localStorage.clear
     */
    clear: function() {
      _clear();
    }
  };
});

var amdi18n={"__root":{"intentsError":"It looks like you don't have access to the System > Basic role. Contact your system administrator and request write permission for the System > Basic role."},"__ja":{"intentsError":"[システム] > [ベーシックロール]の順にアクセスできないようです。システム管理者に問い合わせて、[システム] > [ベーシックロール]における書き込み権限を要求してください。"},"__ko":{"intentsError":"시스템 > 기본 역할에 액세스할 수 없는 것 같습니다. 시스템 관리자에게 문의하여 시스템 > 기본 역할에 대한 쓰기 권한을 요청하십시오."},"__zh":{"intentsError":"您好像不具有访问“系统”>“基本角色”的权限。请与系统管理员联系，申请“系统”>“基本角色”的写入权限。"}};amdi18n.init=function(language){
		// get the default language
		if(!language){
			if(window._i18n && window._i18n.locale){
				language = window._i18n.locale;
			}else if(document.documentElement.lang){
				language = document.documentElement.lang;
			}else{
				language = 'root';
			}
		}
		var target = this['__' + language] || this.__root;

		// copy definition to root level
		if (target) {
			for (var name in target) {
				this[name] = target[name];
			}
		}

		// fallback to root
		for(var name in this.__root){
			if(typeof this[name] === 'undefined'){
				this[name] = this.__root[name];
			}
		}
	};amdi18n.init();module.exports=amdi18n;
define(["jquery", "core/utils/rbac", "rest/auth"], function($, rbac, restAuth) {
  /**
   * Log the user out, taking them to the /login page
   */
  async function _signout() {
    await restAuth.signout().catch(() => {/** ignore */});

    rbac.clearCached();
    localStorage.removeItem("csrf");
    localStorage.removeItem("authSource");
    window.location = "/";
  }

  return {
    authorize: (username, password) => new Promise((resolve, reject) =>
      $.when(restAuth.authorize(username, password))
        .then(
          res => resolve(res),
          err => reject(err)
        )),

    signout: _signout
  };
});

define([], () => {
  const showError = () => {
    document.querySelector("#main>h1").innerHTML = "Unsuccesful login";
    document.querySelector("#error-text").innerHTML =
      "Please return to DMS and try again.";
  };
  const getQueries = () => {
    const query = window.location.search.substr(1);
    const params = query.split("&").filter(v => v);
    return params.reduce((map, p) => {
      var kv = [p.slice(0, p.indexOf("=")), p.slice(p.indexOf("=") + 1)];
      map[decodeURIComponent(kv[0])] = decodeURIComponent(kv[1].replace(/\+/g, "%20"));
      return map;
    }, {});
  };

  return () => {
    const queries = getQueries();
    fetch(`/dms/x-retreive?id=${queries.id}`, {
      credentials: "same-origin"
    })
      .then(res => {
        if (res.status === 200) {
          window.location.href = queries.to ? queries.to : "/";
        } else {
          showError();
        }
      })
      .catch(showError);
  };
});

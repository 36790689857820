export default {
  "warn_msg_browser_incmptbl": "사용 중인 브라우저는 현재 지원되지 않으며, 일부 기능이 예상대로 작동하지 않을 수 있습니다. 전체 호환성을 보장하려면 Chrome 또는 Firefox의 최신 버전을 사용하십시오.",
  "warn_msg_incmpltbl_version": "{{browser}} {{version}} 버전을 사용하고 계신 것 같습니다. 전체 사이트 호환성을 보장하려면 {{browser}}의 최신 버전으로 업데이트하십시오.",
  "label_username": "사용자 이름",
  "label_password": "비밀번호",
  "label_login": "로그인",
  "error_msg_invalid_csrf": "잘못된 CSRF입니다. 페이지를 새로 고치고 다시 시도하십시오.",
  "error_msg_pwd_exp": "비밀번호가 만료되었습니다. 비밀번호를 변경하십시오.",
  "error_msg_invalid_credentials": "잘못된 로그인 크리덴셜",
  "error_msg_unable_to_login": "지금은 로그인할 수 없습니다. 나중에 다시 시도하십시오.",
  "error_uname_psswd_required": "사용자 이름 및 비밀번호 필요",
  "msg_success": "성공!",
  "session_idle_timeout": "사용하지 않아 세션 시간이 초과되었습니다.",
  "msg_access_denied": "액세스 거부됨"

};

export default {
  "warn_msg_browser_incmptbl": "您使用的浏览器目前不受支持，并且某些功能可能无法按预期运行。请使用最新版本的 Chrome 或 Firefox 浏览器以确保完全兼容。",
  "warn_msg_incmpltbl_version": "您看起来使用的是 {{browser}} 版本 {{version}}。请更新为最新版本的 {{browser}} 以确保站点完全兼容。",
  "label_username": "用户名",
  "label_password": "密码",
  "label_login": "登录",
  "error_msg_invalid_csrf": "无效的 CSRF。请刷新页面，然后重试。",
  "error_msg_pwd_exp": "密码已到期。请更改密码",
  "error_msg_invalid_credentials": "登录凭证无效",
  "error_msg_unable_to_login": "目前无法登录。请稍后重试。",
  "error_uname_psswd_required": "用户名和密码是必填项",
  "msg_success": "成功！",
  "session_idle_timeout": "由于长时间无活动，会话已超时",
  "msg_access_denied": "访问被拒绝"

};

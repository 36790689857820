define(["jquery", "axios"], function($, axios) {
  "use strict";

  var cacheServerTime = null; // Holds the last dached serverTime response
  var cacheServerTimeInProgress = false; // Whether or not there is a request in progress
  var cacheServerTimeTimeout = null; // Holds the timeout before performing another cache call
  var cacheServerTimeQueue = []; // Queues all deferreds while a serverTime call is in progress

  return {
    getServerTime: function() {
      var dfd = new $.Deferred();
      if (!cacheServerTime) {
        if (!cacheServerTimeInProgress) {
          cacheServerTimeInProgress = true;
          axios.get("/serverTime").then(response => {
            cacheServerTime = response.data;
            cacheServerTimeInProgress = false;
            cacheServerTimeTimeout = setTimeout(function() {
              cacheServerTimeTimeout = null;
              cacheServerTime = null;
            }, 30000);
            for (var i = 0; i < cacheServerTimeQueue.length; i++) {
              cacheServerTimeQueue[i].resolve(response.data);
            }
            dfd.resolve(response.data);
          })
            .catch(error =>{
              dfd.reject(error);
            })
        } else {
          cacheServerTimeQueue.push(dfd);
        }
      } else {
        dfd.resolve(cacheServerTime);
      }
      return dfd.promise();
    }
  };
});

define({
  api_reference: "API Reference",
  learn: "Learn",
  system: "System",
  help: "Help",
  about_dna_center: "About",
  sign_out: "Sign Out",
  developer_resource: "Developer Resources",
  contact_support: "Contact Support",
  tools: "Tools",
  make_a_wish: "Make a Wish",
  audit_logs: "Audit Logs",
  system_settings: "System Settings"
});

import { Platform } from "@harbor/platform";

import { filterResolvers } from "./filter";
import { fetchRbacPermissions } from "./rbac";


const platform = new Platform({
  development: process.env.NODE_ENV === "development",
  locale: window._i18n.locale,
  pluginConfigs: window.platformUI.pluginConfigs,
  pluginPaths: [],
  rbac: { fetchPermissions: fetchRbacPermissions }
});

platform.filter.addResolvers(filterResolvers);

export { platform };

import memoize from "lodash/memoize";
import {Observable, ReplaySubject} from "rxjs";
import {multicast, refCount} from "rxjs/operators";
import sio from "socket.io-client";

// create a stream of socket.io data from a namespace,
// ensuring that the same stream is returned if invoked
// multiple times with the same namespace.
// this allows us to ensure that only one ws connection
// is created for a given namespace. socket.io
// re-uses the same socket for different namespaces,
// which is a good thing, but oddly, if you create multiple
// io's with the same namespace, it'll create separate socket
// connections for each.
const messageStream = memoize(namespace => {
  const s = new Observable(obs => {
    const io = sio(namespace, {
      transports: ["websocket"]
    });
    io.on("data", data => obs.next(data));
    return () => {
      io.disconnect();
      // if there are zero subscribers, forget the entire
      // outer observable so that we don't end up
      // replaying stale values to the next subscriber
      messageStream.cache.delete(namespace);
    };
  });
  return s.pipe(
    multicast(new ReplaySubject(1)),
    refCount()
  );
});

export default messageStream;

define([
  "jquery",
  "core/utils/auth",
  "core/utils/pluginNavigation",
  "hbs!core/utils/username.hbs",
  "hbs!core/utils/tools-dropdown.hbs",
  "core/utils/serviceRegistry",
  "i18n!./nls/i18_navUserMenu",
  "core/utils/i18n"
], function($, auth, pluginNav, template, toolsTemplate, sr, nls, i18n) {
  let $usernameTemplate,
    $toolsTemplate,
    $settingsButton, // settings menu button
    $appButton, // app menu button
    dropdownList = []; // List of all templates for dropdown

  function _closeAll() {
    dropdownList.forEach(item => {
      $(item).removeClass("show");
    });
  }

  function _hideActive() {
    $(".header-buttons ._active").removeClass("_active");
  }

  async function _init() {
    const branding = await sr.getService("cisco.dna.core.product", "branding");
    const templateData = Object.assign({}, nls, {
      about_dna_center: i18n.getFilledTextForHBS(nls.about_dna_center, branding)
    });
    const navUserMenu = template(templateData);
    const toolsDropDown = toolsTemplate(nls);

    $settingsButton = $(".apic-header-cog-settings");
    $appButton = $(".header-buttons #app-launcher");
    $toolsTemplate = $(toolsDropDown);
    $usernameTemplate = $(navUserMenu);
    sr.getService("cisco.dna.core.rbac", "rbac").then(rbac => {
      $usernameTemplate.find(".username").text(rbac.getUserName());
    });

    const extendedNav = pluginNav.get("user-nav", "platform");
    var extendedNavSystem = pluginNav.get("system", "platform");
    var extendedNavHelp = pluginNav.get("help", "platform");

    function renderMenuItems(items, el) {
      items.forEach(function(item) {
        var s =
          "<li><a href='" +
          item.href +
          "' class='_" +
          item.title.toLowerCase() +
          "'><span>" +
          item.title +
          "</span></a> </li>";
        $(s).insertAfter($usernameTemplate.find(el));
      });
    }

    // For backward compatibility
    const systemAggregatedNav = extendedNav.concat(extendedNavSystem);
    renderMenuItems(systemAggregatedNav, ".menu-system");
    renderMenuItems(extendedNavHelp, ".menu-help");
    // renderMenuItems( extendedNavUser, '.menu-user' );

    //append template
    $settingsButton.append($usernameTemplate);
    dropdownList.push($usernameTemplate);
    $appButton.append($toolsTemplate);
    dropdownList.push($toolsTemplate);

    //attach events
    $settingsButton.click(e => {
      e.stopPropagation();
      _hideActive();
      _closeAll();
      $settingsButton.find(".btn").addClass("_active");

      $usernameTemplate.addClass("show");
    });

    $appButton.click(e => {
      e.stopPropagation();
      _hideActive();
      _closeAll();
      $appButton.find(".btn").addClass("_active");

      $toolsTemplate.addClass("show");
    });

    // Populate app list
    sr.getService("cisco.dna.core.home", "applist").then(apps => {
      if (apps.length) {
        let toolsHtml = apps.reduce((html, app) => {
          let app_href = app.defaultRoute || app.href;
          let className = location.pathname == app_href ? "active" : "";
          return (
            html +
            `
            <li data-app-id="${app.uuid}" class="${className}" data-app-title="${
              app.title
            }">
                <a href="${app_href}">
                  <span>${app.title}</span>
                </a>
            </li>`
          );
        }, "");

        $toolsTemplate.append(toolsHtml);
      } else {
        $appButton.css("display", "none");
      }
    });

    $("._changePasswordHolder").click(function(e) {
      window.location = "/settings/changePassword";
    });

    document.body.addEventListener("click", function() {
      _closeAll();
      _hideActive();
    });

    $("._signoutHolder").click(function() {
      auth.signout();
    });
  }
  return {
    init: _init
  };
});

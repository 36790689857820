define([
  "jquery"
],	function($)	{
  "use strict";


  function _getDeviceCount(filter) {
    const dfd = new $.Deferred();
    dfd.resolve({});
    return dfd.promise();
  }

  return {
    getAllDevices: function(fn)	{
      const dfd = new $.Deferred();
      dfd.resolve({});
      return dfd.promise();
    },

    getDeviceById: function(id, fn)	{
      const dfd = new $.Deferred();
      dfd.resolve({});
      return dfd.promise();
    },

    getDeviceByIp: function(ip)	{
      const dfd = new $.Deferred();
      dfd.resolve({});
      return dfd.promise();
    },

    getAllDeviceInfo: function(offset, batchsize, sortCol, sortOrder, filter)	{
      const dfd = new $.Deferred();
      dfd.resolve({});
      return dfd.promise();
    },
    getDeviceCount: function(filter)	{
      return _getDeviceCount(filter);
    },
    autocomplete: function(colId, term)	{
      const dfd = new $.Deferred();
      dfd.resolve({});
      return dfd.promise();
    }
  };
});

define([
  'jquery',
  'backbone',
  'moment'
],
function($, Backbone, Moment) {
    // this file contains helper routines for the uci ui controller code
    return {
        // helper function to get the length of an object
        getObjectLength: function(obj) {
            var length=0;
            $.each(obj, function(i, elem) {
                length++;
            });
            return length;
        },
        // helper function to map an array to a key value object pair
        create_obj_mapping: function(arr) {
            var obj = {};
            for (var i = 0; i < arr.length; i++) {
                obj[arr[i]] = {name: arr[i], id: i};
            }

            return obj;
        },
        // generates a uuid
        generate_uuid: function() {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
            }
            return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
        },
        // removes duplicates from an array
        remove_duplicates: function(list) {
            var seen = {};
            return list.filter(function(item) {
                return seen.hasOwnProperty(item) ? false : (seen[item] = true);
            });
        },
        // checks to see if the object exists in an array of objects based on id
        is_duplicates_array_of_objs: function(obj_list, target) {
            var seen = false;
            _.each(obj_list, function(obj) {
                if(obj.get('id') == target.get('id')) {
                    seen = true;
                }
            });
            return seen;
        },
        //helper function to get current Time in valid UCI Format
        getCurrentDateAndTimeInValidUciFormat: function(){
            return moment().format('MM-DD-YY');
        },
        getTimeZoneAbbreviation: function(){
            var tTimeZone = new Date().toString().match(/\(([A-Za-z\s].*)\)/)[1];
            tTimeZone = tTimeZone.split(" ");
            return tTimeZone;
        },
        replacePeriodWithUnderScore: function(str){
            return str.replace(/\./g, '_');
        },
        convertFirstCharInStringToUpperCase: function(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        convertStringToCamelCase: function(str){
            var finalStr = '';
            if(str.indexOf('_') != -1){
                var strArray = str.split('_');
                finalStr = strArray[0].charAt(0).toUpperCase() + strArray[0].slice(1).toLowerCase();
                strArray.forEach(function(value, index){
                    if(index > 0){
                        finalStr += ' ' + value.toLowerCase();
                    }
                });
            }
            else{
                finalStr = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
            }
            return finalStr
        },
        convertFirstCharInBooleanToUpperCase: function(bool) {
            switch(bool) {
                case true:
                    return "True";
                break;

                case false:
                    return "False";
                break;

                default:
                    return "Not Available";
                break;
            }
        }
    };
});

__webpack_public_path__ = `${window.appcontext.config.basePath}dist/`; // eslint-disable-line no-undef
require("./styles.js");

// AMD define all the modules returned by the given context
const defineAll = (rootName, context) => {
  context.keys().forEach((modPath) => {
    const path = `${rootName}/${modPath.slice(2, -3)}`;
    window.define(path, () => context(modPath));
  });
};

// lazy AMD define for loading chunks
const defineLazy = (name, importFn, deps = []) => {
  window.define(`__lazy__${name}`, importFn);
  window.define(name, [`promise-loader!__lazy__${name}`, ...deps], (m) => m.default);
};

// load requirejs + config
const rjs = require("requirejs-umd");
window.requirejs = rjs.requirejs;
window.require = rjs.require;
window.define = rjs.define;
window.require.config(require("./core/requirejsAdmin"));

// define our core modules

// todo: deprecate defineAll for explicit imports
defineAll("core", require.context("./core", true, /\.js$/));
defineAll("fusion", require.context("./fusion", true, /\.js$/));
defineAll("rest", require.context("./rest", true, /\.js$/));
defineAll("dna", require.context("./dna", true, /\.js$/));

// core  modules
window.define("@pui/platform", () => require("./core/harbor-platform/service"));

// define our custom loaders
window.define("promise-loader", () => require("./core/utils/promiseAmdLoader"));
window.define("dna-service", require("./core/utils/serviceAmdLoader"));

//
// define our libraries
//
require("./app/apic-em-widgets.js");
window.define("@cisco-dna/dnx-web-components", () =>
  require("./core/utils/dnxCustomElementsLoader")
);
window.define("@cisco-dna/dnx-react-components", () =>
  require("./core/utils/dnxCustomElementsReactLoader")
);
window.define("@cisco-dna/icons", () => require("@cisco-dna/icons"));
require("./app/harbor-elements.js");

//
// define third party shared libraries
//

// libraries that expose / add to globals, unfortunately
window.define("domReady", () => require("./vendor/requirejs/domReady/domReady"));
const jquery = require("jquery");
window.$ = window.jQuery = jquery;
window.define("jquery", () => jquery);
const underscore = require("underscore");
require("lodash");
window._.noConflict();
window._ = underscore;
window.define("underscore", () => underscore);
window.define("backbone", () => require("backbone") /* also sets window.Backbone */);
window.define("backbone.radio", () => require("backbone.radio"));
window.define("backbone.marionette", () => require("backbone.marionette"));
window.define("backbone.stickit", () => require("backbone.stickit"));
window.define("backbone-pkg", () => {
  const bb = require("backbone");
  require("backbone.stickit");
  return bb;
});

// return a version of fn that caches the result
// like memoize, but ignores args
const cached = (fn) =>
  (() => {
    let cached, invoked;
    return () => {
      if (!invoked) {
        cached = fn();
        invoked = true;
      }
      return cached;
    };
  })();

// window.define a shared lib, possibly under multiple names
const defineLib = (names, fn) => {
  names.forEach((name) => window.define(`dna/shared-libs/${name}`, cached(fn)));
};

// deprecated
window.define("handlebars", () => require("handlebars"));
const jqueryui = require("./vendor/jquery-ui/jquery-ui");
window.define("jqueryui", () => jqueryui);
window.define("webui-popover", () => {
  require("webui-popover/dist/jquery.webui-popover.css");
  return require("webui-popover");
});
window.define("dna/shared-libs/react-bootstrap/v0.30", () => require("react-bootstrap"));
// deprecated (defined further below using dna/shared-libs prefix)
window.define("moment", () => require("moment"));

// better behaved libs
window.define("dna/shared-libs/moment", () => require("moment"));
defineLib(["axios", "axios/v0.24"], () => require("./core/utils/axios"));
defineLib(["react", "react/v15"], () => require("react"));
defineLib(["react-dom", "react-dom/v15"], () => require("react-dom"));
defineLib(["redux", "redux/v3"], () => require("redux"));
defineLib(["react-redux", "react-redux/v5"], () => require("react-redux"));
defineLib(["redux-thunk", "redux-thunk/v2"], () => require("redux-thunk"));
defineLib(["redux-promise-middleware", "redux-promise-middleware/v4"], () =>
  require("redux-promise-middleware")
);
defineLib(["rxjs", "rxjs/v6"], () => {
  const rxjs = require("rxjs");
  rxjs.operators = require("rxjs/operators");
  return rxjs;
});

// we define amcharts libs because they're referenced as externals by both
// apic-em-widgets and dnx components.  in an ideal future, we'll remove the
// global defines and have them tucked privately into dnx components
const amchartsImportFn = () =>
  import(/* webpackChunkName: "amcharts" */ "./app/amcharts");
[
  "amcharts",
  "amcharts.serial",
  "amcharts.themes.light",
  "amcharts.pie",
  "amcharts.responsive",
  "amcharts.export",
  "amcharts.xy",
  "amcharts.gantt"
].forEach((name) => defineLazy(name, amchartsImportFn));

// leaflet & mapbox
const mapsImportFn = (name) => () =>
  import(/* webpackChunkName: "maps" */ "./app/maps").then((r) => r[name]);
["leaflet", "mapbox", "mapboxLabel", "mapboxMarkerCluster"].forEach((name) =>
  defineLazy(name, mapsImportFn(name))
);

// Tables 2.0 lazyloaded
const tablesImportFn = (name) => () =>
  import(/* webpackChunkName: "dtable" */ "./app/dtable").then((r) => r[name]);
[
  "@cisco-dna/dnx-web-components/table",
  "@cisco-dna/dnx-react-components/table"
].forEach((name) => defineLazy(name, tablesImportFn(name)));

const dnxChunksImportFn = (name) => () =>
  import(/* webpackChunkName: "dnxchunks" */ "./app/dnxChunks").then((r) => r[name]);
[
  "@cisco-dna/dnx-web-components/chunks",
  "@cisco-dna/dnx-react-components/chunks"
].forEach((name) => defineLazy(name, dnxChunksImportFn(name)));

const IMAGES = require.context("./img");

const images = [
  "./login_background_001.jpg",
  "./login_background_002.jpg",
  "./login_background_003.jpg",
  "./login_background_004.jpg",
  "./login_background_005.jpg",
  "./login_background_006.jpg",
  "./login_background_007.jpg",
  "./login_background_008.jpg",
  "./login_background_009.jpg",
  "./login_background_010.jpg",
  "./login_background_011.jpg",
  "./login_background_012.jpg",
  "./login_background_013.jpg",
  "./login_background_014.jpg",
  "./login_background_015.jpg",
  "./login_background_016.jpg",
  "./login_background_017.jpg",
  "./login_background_018.jpg",
  "./login_background_019.jpg"
];

/**
 * Returns a URL to a background image to use for the login page
 */
export default function getRandomBackground() {
  const background = images[Math.floor(Math.random() * images.length)];
  return IMAGES(background);
}
define([], function() {

	/*
		Return an object of BrowserName: Version that we support.
		Will support anything > browser version
	*/
	return {
		chrome: 66,
		firefox: 60
	};

});
/***************************************************

   Simple Cookie Params getItem/setItem/removeItem
   apicem/utils/cookie
   @cisco.com

   Allows for simple manipulation of cookies

   Usage:
   -------------------
   cookie.setItem({key: "myCookieValue", value: "This is a string I placed here", expiration: 5}); // -- 5 = 5 seconds (auto multipled by 1000ms)
   cookie.getItem("myCookieValue") // -- returns "This is a string I placed here"
   cookie.removeItem("myCookieValue") // -- clears the cookie

****************************************************/

define([], function() {

  function _empty(key) {
    _set({key: key, value: "", expiration: -1});
  }

  function _get(key) {
    return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(key).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
  }

  function _set(options) {
    if ((! options instanceof Object) || !options.hasOwnProperty("key") || !options.hasOwnProperty("value") || !options.hasOwnProperty("expiration")) {
      throw("The expected cookie options were not received.");
    }
    // Expiration is already set to multiply by 1000ms so pass in seconds time value only
    var date = new Date();
    date.setTime(date.getTime() + options.expiration * 1000);
    document.cookie = encodeURIComponent(options.key) + "=" + encodeURIComponent(options.value) + "; expires=" + date.toGMTString() + "; path=/";
  }


  return {
    removeItem: function(key) {
      _empty(key);
    },

    getItem: function(key) {
      return _get(key);
    },

    setItem: function(options) {
      /*
      options = {
        key: string name of cookie item
        value: value to set cookie as
        expiration: in seconds (not ms)
      }
      */
      if (options.expiration === undefined) {
        options.expiration = 604800; // Default to 7 days before expire
      }
      _set(options);
    }

  };
});
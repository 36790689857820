export default {
  "warn_msg_browser_incmptbl": "お使いのブラウザはサポートされていないため、一部の機能が正常に機能しない可能性があります。完全な互換性を確保するには、最新バージョンのChromeまたはFirefoxを使用してください。",
  "warn_msg_incmpltbl_version": "お使いの{{browser}}のバージョンは{{version}}のようです。{{browser}}の最新バージョンに更新し、完全な互換性を確保してください。",
  "label_username": "ユーザ名",
  "label_password": "パスワード",
  "label_login": "ログイン",
  "error_msg_invalid_csrf": "無効なCSRFです。ページを更新してからもう一度やり直してください。",
  "error_msg_pwd_exp": "パスワードの有効期限が切れています。パスワードを変更してください",
  "error_msg_invalid_credentials": "ログインクのレデンシャルが無効です",
  "error_msg_unable_to_login": "現時点でログインできません。時間をおいて再試行してください。",
  "error_uname_psswd_required": "ユーザ名およびパスワードが必要です",
  "msg_success": "成功しました。",
  "session_idle_timeout": "長時間操作されなかったため、セッションがタイムアウトになりました",
  "msg_access_denied": "アクセスが拒否されました"

};

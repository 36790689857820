window.__pluginSandboxContext = (function() {
  function addApiMessageListener(api) {
    window.addEventListener("message", function(e) {
      var messageKey = e.message ? "message" : "data";
      var data = e[messageKey];
      var response = api[data.key];
      if (typeof response !== "function") {
        window.parent.postMessage({response: response, messageID: data.messageID}, window.parent.location.href);
      }
      else {
        // Perform Promise resolve as function may return without it being a guaranteed promise
        Promise.resolve(response.apply(null, data.args || [])).then(function(fnResult) {
          var responseMessage = {response: fnResult, messageID: data.messageID};
          // if (typeof fnResult !== "function") {
          window.parent.postMessage(responseMessage, window.parent.location.href);
        });
      }
    }, false);
  }

  return {
    __export: function(api) {
      var exportMessage = {message: "export"};
      if (typeof api === "object") {
        // if an object is exported, tell the host plugin about its properties
        // so that it can create a shim object
        addApiMessageListener(api);
        exportMessage.keys = Object.keys(api);
      }
      else {
        // otherwise we send the value directly.  must be serializable.
        exportMessage.value = api;
      }
      window.parent.postMessage(exportMessage, window.parent.location.href);
    },

    sendMessage: function(trigger, payload) {
      window.parent.postMessage({trigger: trigger, payload: payload}, window.parent.location.href);
    }
  };
})();

export default {
  "timermessage1": "操作していない状態が続いています。",
  "timermessage2": "セキュリティのため、間もなく自動的にログアウトされます。",
  "timermessage": "残り時間は{{minutes}}分、{{seconds}}秒です...",
  "timermessage_showSeconds": "{{seconds}}秒残っています...",
  "timeoutwarning": "セッションタイムアウト",
  "endsession": "ログアウト",
  "extendsession": "サインインしたままにする"

};

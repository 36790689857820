import { BroadcastChannel as broadcastChannel} from 'broadcast-channel';
const CHANNEL_NAME="dna-platform-idleSessionTimer";
let broadcastchannel=null;
if (window.BroadcastChannel) {
    broadcastchannel=new BroadcastChannel(CHANNEL_NAME)
} else {
    broadcastchannel= new broadcastChannel(CHANNEL_NAME);
}

export default broadcastchannel;


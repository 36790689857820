define(function(require, exports, module) {
  const localStorage = require("core/apicem/utils/localStorage");

  let flags = null;
  function getFlags() {
    if (!flags) {
      const contribs = require("core/utils/PluginContributions");
      flags = contribs("cisco.dna.core")
        .forExtensionPoint("featureFlag")
        .map(e => Object.assign({}, e.data, {sourcePluginId: e.sourcePluginId}))
        .map(val => {
          const {sourcePluginId, id, category} = val;
          const qualId = `${sourcePluginId}/${id}`;
          return Object.assign({}, val, {
            id: qualId,
            category: category || qualId
          });
        });
    }
    return flags;
  }

  const mod = {
    get flags() {
      return getFlags();
    },
    storageKey(flagId) {
      return `cisco.dna.core.featureFlag.${flagId}`;
    },
    isEnabled(flagId) {
      return localStorage.getItem(mod.storageKey(flagId)) === "true";
    },
    enable(flagId) {
      localStorage.setItem(mod.storageKey(flagId), flagId ? "true" : "false");
    }
  };
  module.exports = Object.freeze(mod);
});

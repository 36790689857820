/***************************************************

   Loader Widget
   core/widgets/loader/Loader

   Displays a loading animation. Useful to initiate before any HTTP requests,
   		or code blocks that may take longer than a few milliseconds. Also
   		prints to console how long the load took


   Usage:
   -------------------
   Loader.show("Getting this data");
   Loader.done();

****************************************************/
define(["jquery", "text!core/widgets/loader/loader.html"], function($, template) {
  var in_progress = false;
  var current = "";
  var _template, date;

  function _save(msg, time) {
    var page = appcontext.name;
    var POST = {
      msg: msg,
      time: time.toString(),
      page: page
    };
  }

  function _init() {
    if (!_template) {
      _template = $(template);
      _template.appendTo("body").hide();
    }
  }
  function _startLoad(msg) {
    if (in_progress) {
      _finishLoad(current, false);
    }

    date = new Date();
    console.time(msg);

    _template.find(".load-text").text(msg);

    if (!in_progress) {
      _template.slideDown(150);
    }

    current = msg;
    in_progress = true;
  }

  function _finishLoad(msg, hide) {
    console.timeEnd(msg);

    var d = new Date();
    var time = d - date;

    _save(msg, time);

    if (hide && _template) {
      _template.slideUp();
      in_progress = false;
    }
  }

  return {
    start: function(msg) {
      _init();
      _startLoad(msg);
    },
    done: function() {
      _finishLoad(current, true);
    }
  };
});

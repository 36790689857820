export default {
  "learn": "学习",
  "api_reference": "API 参考",
  "system": "系统",
  "help": "帮助",
  "about_dna_center": "关于",
  "sign_out": "注销",
  "developer_resource": "开发者资源",
  "tools": "有用工具",
  "make_a_wish": "改进建议",
  "audit_logs": "审核日志",
  "system_settings": "系统设置",
  "contact_support": "联系支持团队"

};

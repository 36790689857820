export default {
  "learn": "학습",
  "api_reference": "API 참조",
  "system": "시스템",
  "help": "도움말",
  "about_dna_center": "정보",
  "sign_out": "로그아웃",
  "developer_resource": "개발자 리소스",
  "tools": "툴",
  "make_a_wish": "바라는 점",
  "audit_logs": "감사 로그",
  "system_settings": "시스템 설정",
  "contact_support": "지원 문의"

};

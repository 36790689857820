define([], function() {
  const blockMap = new Map(); // <Object, Set<prop:String>

  // Block polyfilling of the given prop on the given
  // root object.  If root is an empty string,
  // the root object is assumed to be window, otherwise
  // it is window[root].
  function blockPolyfill(root, prop) {
    const rootObj = root ? window[root] : window;
    if (!blockMap.get(rootObj)) blockMap.set(rootObj, new Set());
    blockMap.get(rootObj).add(prop);
    const orig = Object.getOwnPropertyDescriptor(rootObj, prop);
    Object.defineProperty(rootObj, prop, {
      configurable: false, // prevent polyfilling by invocation of defineProperty
      get() {
        return orig.value;
      },
      set() {
        // this prevents polyfilling by assignment
        console.warn(`Polyfill of ${root ? root + "." : ""}${prop} is not permitted.`);
      }
    });
  }

  const blockList = [
    ["", ["Promise", "fetch", "cisco", "appcontext", "_"]],
    ["Array", ["from"]],
    ["Object", ["assign"]]
  ];
  blockList.forEach(([object, props]) =>
    props.forEach(prop => blockPolyfill(object, prop))
  );

  // If code attempts to re-define a property that
  // we've blocked, defineProperty will normally
  // throw an exception.   To prevent catastrophic
  // failure, we override defineProperty to catch
  // this exception and print a warning to the
  // console instead.
  const origDP = Object.defineProperty.bind(Object);
  Object.defineProperty = function(obj, prop, ...args) {
    try {
      return origDP(obj, prop, ...args);
    } catch (err) {
      const keys = blockMap.get(obj);
      if (keys && keys.has(prop)) {
        console.warn(`Polyfill of ${obj}.${prop} is not permitted\n`, err);
      } else {
        throw err;
      }
    }
  };
});

export default {
  "learn": "学習",
  "api_reference": "API リファレンス",
  "system": "システム",
  "help": "ヘルプ",
  "about_dna_center": "バージョン情報",
  "sign_out": "サインアウト",
  "developer_resource": "開発者リソース",
  "tools": "ツール",
  "make_a_wish": "Make a Wish",
  "audit_logs": "監査ログ",
  "system_settings": "システム設定",
  "contact_support": "サポートに問い合わせる"

};

import statusCodeError from "./httpErrors";
import browserWarning from "./browserWarning";
import nls from "i18n!./nls/i18n_login";

export const AlertState = {
  None: "",
  Error: "error",
  Warning: "warning",
  Success: "success"
};

export const initialState = {
  // branding
  productName: "",
  productDescription: "",

  // form state
  browserWarning: browserWarning(),
  alertText: "",
  alertState: AlertState.None,
  loading: false,
  loginMessage: "",

  // input state
  username: "",
  password: ""
}

/** When a user types into an input text */
const INPUT = "INPUT";
export const input = (key, value) => ({
  type: INPUT,
  key, value
});

/** Branding is loaded asynchronously, use this to update it when it resolves */
const BRANDING_READY = "BRANDING_READY";
export const brandingReady = ({productName, productDescription}) => ({
  type: BRANDING_READY,
  productName, productDescription
});

/** Users can create a login message in settings to show underneath the form (as a notification to everyone) */
const HAS_LOGIN_MESSAGE = "HAS_LOGIN_MESSAGE";
export const hasLoginMessage = (message) => ({
  type: HAS_LOGIN_MESSAGE,
  message
});

/** When the user was auto-logged out from idle */
const LOGGED_OUT_FROM_IDLE = "LOGGED_OUT_FROM_IDLE";
export const idleLogOut = () => ({
  type: LOGGED_OUT_FROM_IDLE
});

/** User was force logged out, like when an API returned unauthorized */
const FORCE_LOGGED_OUT = "FORCE_LOGGED_OUT";
export const forceLoggedOut = (message) => ({
  type: FORCE_LOGGED_OUT,
  message
});

/** Fired when login is hit */
const LOGIN_STARTED = "LOGIN_STARTED";
export const requestStarted = () => ({
  type: LOGIN_STARTED
});

/** Welcome to DNAC! */
const LOGIN_SUCCEEDED = "LOGIN_SUCCEEDED";
export const loginSucceeded = () => ({
  type: LOGIN_SUCCEEDED
});

/** Most likely a typo */
const LOGIN_FAILED = "LOGIN_FAIL";
export const loginFailed = (statusCode, error) => ({
  type: LOGIN_FAILED,
  statusCode, error
});

/** Update the form state */
export const reducer = (state, action) => {
  switch (action.type) {
  case BRANDING_READY: return {
    ...state,
    productName: action.productName,
    productDescription: action.productDescription
  };

  case HAS_LOGIN_MESSAGE: return {
    ...state,
    loginMessage: action.message
  };

  case LOGGED_OUT_FROM_IDLE: return {
    ...state,
    alertText: nls.session_idle_timeout,
    alertState: AlertState.Warning
  };

  case FORCE_LOGGED_OUT: return {
    ...state,
    alertText: action.message,
    alertState: AlertState.Error
  };

  case INPUT: return {
    ...state,
    [action.key]: action.value
  };

  case LOGIN_STARTED: return {
    ...state,
    loading: true,
    alertText: "",
    alertState: AlertState.None
  };

  case LOGIN_SUCCEEDED: return {
    ...state,
    alertText: nls.msg_success,
    alertState: AlertState.Success
  };

  case LOGIN_FAILED: {
    const error = statusCodeError(action.statusCode, action.error);
    return {
      ...state,
      loading: false,
      alertText: error,
      alertState: AlertState.Error
    };
  }

  default: return state;
  }
}
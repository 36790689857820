define([
  "i18n!./nls/i18_maintenanceCheck",
  "./constants.js"
],
function(
  i18nMessageBundle,
  constants
) {
  const root = document.getElementById("root"),
    progressEl = document.getElementById("progress"),
    progressBarEl = document.getElementById("progress-bar"),
    percentComplete = document.getElementById("percent-complete"),
    currentItem = document.getElementById("current-item"),
    tasksList = document.getElementById("tasks-list"),
    systemUpdateEl = document.getElementById("system-update-details"),
    errorWarning = document.getElementById("error-warning");

  let _isAlternativeApi = false;
  let _requestIsPending = false;

  let _tasksListIsShown = false;
  let _actionsWereSet = false;

  let _systemUpdateApiResponded = false;

  const _globalTimeInterval = 15000;
  const _globalTimeout = 10000;

  let _globalPoller;

  let _useOlderApi = false;

  const resetBrowser = location => (window.location.href = location);

  const errorTimer = {
    timer: null,
    init() {
      this.timer = setTimeout(() => {
        errorWarning.style.display = "block";
      }, 900000);
    },
    reset() {
      clearTimeout(this.timer);
      this.init();
    }
  };
  errorTimer.init();

  const _switchToAlternativeApi = obj => {
    if ((!_isAlternativeApi && obj.isUpdateStatusApi)
        || (_isAlternativeApi && obj.isAlternativeUpdateStatusApi)) {
      _isAlternativeApi = !_isAlternativeApi;
    }
  };

  const deleteAllCookies = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  };

  const _clearLocalStorage = () => {
    deleteAllCookies();
    localStorage.clear();
    resetBrowser("/");
  };

  const request = obj =>
    new Promise(resolve => {
      const xhr = new XMLHttpRequest();

      xhr.open("GET", obj.url);
      xhr.setRequestHeader(
        "X-CSRF-TOKEN",
        localStorage.getItem("csrf") || ""
      );
      xhr.timeout = _globalTimeout;

      xhr.onload = () => {
        _requestIsPending = false;
        if (xhr.status === 200) {
          errorTimer.reset();
          try {
            resolve(JSON.parse(xhr.response));
          } catch (e) {
            restart();
          }
        } else if (xhr.status === 401) {
          _clearLocalStorage();
        } else {
          _switchToAlternativeApi(obj);
          restart();
        }
      };

      xhr.onerror = () => {
        if (xhr.status === 401) {
          _clearLocalStorage();
        } else {
          _requestIsPending = false;
          _switchToAlternativeApi(obj);
          restart();
        }
      };

      xhr.ontimeout = () => {
        _requestIsPending = false;
        _switchToAlternativeApi(obj);
        restart();
      };

      xhr.send(obj.body);
      _requestIsPending = true;
    });

  const getMainCheckOfMainModeStatus = () => request({
    url: "/api/system/v1/maglev/maintenance/status"
  });

  const getRestoreStatus = () => request({ url: "/api/system/v1/maglev/restore/progress" });

  const getUpdateStatus = () => {
    return request({
      url: !_useOlderApi ? "/api/system/v1/maglev/release" : "/api/system/v1/systemupdater/common/update_status",
      isUpdateStatusApi: true
    });
  };

  const getScaleDetails = () => request({ url: "/api/system/v1/maglev/services/node-scale/status" });

  const getAlternativeUpdateStatus = () => {
    const mainUrlPart = `${window.location.protocol}//${window.location.hostname}`;
    request({
      url: `${mainUrlPart}:26375/api/system/v1/system_updater_standby/common/update_status`,
      isAlternativeUpdateStatusApi: true
    });
  };

  const _getTakenTime = takenTime => {
    let unit = "Second(s)";
    let result = takenTime;
    if (takenTime > 60) {
      result = Math.round(takenTime / 60);
      unit = "Minute(s)";
    }
    return `${result} ${unit}`;
  };

  const _getTasksListTemplate = tasks => {
    let tasksList = "";

    if (tasks && tasks.length > 0) {
      const successedTasks = [];
      const pendingTasks = [];

      tasks.forEach(task => {
        if (task.status === "SUCCESS" || task.status === "FAILED") {
          successedTasks.push(task);
        } else {
          pendingTasks.push(task);
        }
      });

      pendingTasks.concat(successedTasks).forEach(task => {
        tasksList = `
          ${tasksList}
          <div class="task-row">
            <div class="name">
              ${constants.backupRestoreTasks[task.task_name] || task.task_name}
            </div>
            <div class="status">${task.status}</div>
            <div class="time-left">
              ${
  task.end_timestamp && task.start_timestamp
    ? `Took ${
      _getTakenTime(Math.round(task.end_timestamp - task.start_timestamp))
    }`
    : "..."
}
            </div>
          </div>
        `;
      });
    }

    return tasksList;
  };

  const showRestoreProgress = data => {
    const completed = data.filter(task => task.status === "SUCCESS").length,
      progress = ((completed / data.length) * 100).toString().substr(0, 2);

    progressBarEl.classList.add("update-progress");
    percentComplete.style.width = `${progress}%`;
    progressEl.innerHTML = `${progress}% complete`;

    currentItem.innerHTML = `
        ${completed} of ${data.length} tasks completed.
        <span id="show-tasks-details">
          ${_tasksListIsShown ? "Hide" : "Show"} details.
        </span>
      `;
    tasksList.innerHTML = _getTasksListTemplate(data);
  };

  const _getScaleTasksListTemplate = tasks => {
    let tasksList = "";

    tasks.forEach(task => {
      tasksList = `
        ${tasksList}
        <div class="task-row">
          ${task}
        </div>
      `;
    });

    return tasksList;
  };

  const showScaleProgress = ({
    completedTaskCount,
    progressPercent,
    taskCount,
    runningTasks
  }) => {
    progressBarEl.classList.add("update-progress");
    percentComplete.style.width = `${progressPercent}%`;
    progressEl.innerHTML = `${progressPercent}% complete`;

    currentItem.innerHTML = `
      ${completedTaskCount} of ${taskCount} tasks completed.
      ${runningTasks && runningTasks.length > 0 ? `
        <span id="show-tasks-details">
          ${_tasksListIsShown ? "Hide" : "Show"} details.
        </span>
      ` : ""}
    `;

    const systemDetails = document.getElementById("system-update-details");
    if (systemDetails) {
      systemDetails.remove();
    }
    tasksList.innerHTML = _getScaleTasksListTemplate(runningTasks);
  };

  const _getSystemUpdateProcessDetails = progressDetails => {
    const descriptionPhaseBlock = progressDetails ? `
        <div class="current-title">
          Phase:
        </div>
        <div class="current-value">
          ${progressDetails}
        </div>
      ` : "";

    return `
      <div class="details-block">
        <div class="block">
          ${descriptionPhaseBlock}
        </div>

        <div class="system-update-progress-message">
          ${i18nMessageBundle.please}
        </div>
      </div>
    `;
  };

  const showUpdateStatus = obj => {
    if (obj.progressPercentage) {
      progressBarEl.classList.add("update-progress");
      currentItem.innerHTML = `${obj.progressPercentage}% complete`;
      percentComplete.style.width = `${obj.progressPercentage}%`;
    }

    systemUpdateEl.innerHTML = _getSystemUpdateProcessDetails(obj.progressDetails);
  };

  const checkAuth = res => {
    return new Promise((resolve, reject) => {
      if (
        res.message === "Unauthorized"
          || res.message === "Invalid signature"
          || res.error
      ) {
        reject();
      } else {
        let errorCode = res.response.errorCode;
        if (
          res.response.maintenance !== "false"
            && !errorCode
            && (!res.response.installedVersion
              || (res.response.installedVersion && res.response.installedVersion.length === 0))
        ) {
          errorCode = "MAINTENANCE_SYSTEM_UPDATE_IN_PROGRESS";
        }
        resolve({
          errorCode,
          errorMessage: res.response.error
        });
      }
    });
  };

  const whichErr = res => {
    const errorCodes = {
      MAINTENANCE_IN_PROGRESS_ERROR: {
        poll: getMainCheckOfMainModeStatus,
        text: i18nMessageBundle.maintenance_in_progress,
        zcase: s => s.response.maintenance === "false",
        redirectUrl: "/"
      },
      MAINTENANCE_RESTORE_IN_PROGRESS: {
        poll: getRestoreStatus,
        text: i18nMessageBundle.restoring_backup,
        zcase: s => {
          if (s.response && s.response.length) {
            showRestoreProgress(s.response[0].tasks);
            return s.response[0].status === "SUCCESS";
          }
          return true;
        },
        redirectUrl: "/dna/systemSettings/backup?st-systemSettings=backup&st-backupsTabContainer=backups"
      },
      MAINTENANCE_SCALE_IN_PROGRESS: {
        poll: getScaleDetails,
        text: i18nMessageBundle.system_scale_in_progress,
        zcase: s => {
          if (s.response.nodeScaleStatus === "in_progress" && s.response.workflowProgress) {
            showScaleProgress(s.response.workflowProgress);
            return s.response.maintenance === "false";
          }
          return s.response.nodeScaleStatus === "completed" || s.response.maintenance === "false";
        },
        redirectUrl: "/dna/systemSettings/system360"
      },
      MAINTENANCE_SYSTEM_UPDATE_IN_PROGRESS: {
        poll: !_isAlternativeApi ? getUpdateStatus : getAlternativeUpdateStatus,
        text: _systemUpdateApiResponded
          ? i18nMessageBundle.system_update_in_progress : i18nMessageBundle.checking_system_update,
        zcase: s => {
          _systemUpdateApiResponded = true;
          if (s.response) {
            const res = s.response;

            if (
              Object.keys(res).length === 0
                || !res.progress
                || (res.progress && Object.keys(res.progress).length === 0)
            ) {
              _useOlderApi = true;
            }

            if (!_useOlderApi && !_isAlternativeApi) {
              if (res.progress && res.status !== "IDLE") {
                let progressDetails;
                if (res.progress.stepsProgress) {
                  if (res.progress.stepsProgress.system && res.progress.stepsProgress.system.inProgress) {
                    progressDetails = res.progress.stepsProgress.system.progressDetails;
                  } else if (res.progress.stepsProgress.packages && res.progress.stepsProgress.packages.inProgress) {
                    progressDetails = res.progress.stepsProgress.packages.progressDetails;
                  }
                }

                showUpdateStatus({
                  progressPercentage: res.progress.progressPercentage,
                  progressDetails
                });
              }

              return (res.progress && res.progress.progressPercentage === 100) || res.status === "FAILED" || res.status === "IDLE";
            }
            const { updateProgressPercent, updaterState } = s.response;
            if (updateProgressPercent !== "IDLE") {
              showUpdateStatus({
                progressPercentage: updateProgressPercent,
                progressDetails: s.response.currentPhaseDetails || s.response.updaterStatusMessage
              });
            }
            return (
              updateProgressPercent === 100
                  || updaterState === "IDLE"
                  || updaterState === "FAILED"
            );
          }

          return true;
        },
        redirectUrl: "/dna/systemSettings/softwareManagement"
      },
      INVALID_REQUEST_ERROR: {
        poll: getMainCheckOfMainModeStatus,
        text: i18nMessageBundle.checking_maintenance_mode,
        zcase: s => s.response.maintenance === "false",
        redirectUrl: "/"
      },
      PASSIVE_CLUSTER_ERROR: {
        poll: getMainCheckOfMainModeStatus,
        text: res.errorMessage,
        zcase: s => s.response.maintenance === "false",
        redirectUrl: "/"
      },
      WITNESS_CLUSTER_ERROR: {
        poll: getMainCheckOfMainModeStatus,
        text: res.errorMessage,
        zcase: s => s.response.maintenance === "false",
        redirectUrl: "/"
      }
    };

    return Promise.resolve(
      errorCodes[res && res.errorCode ? res.errorCode : "MAINTENANCE_IN_PROGRESS_ERROR"]
        || errorCodes.MAINTENANCE_IN_PROGRESS_ERROR
    );
  };

  const setErrText = err => {
    root.innerHTML = `${err.text}...`;
    return Promise.resolve(err);
  };

  const handleAuthError = (res, err) => {
    if (
      res.message === "Unauthorized"
        || res.message === "Invalid role id"
        || res.error
    ) {
      _clearLocalStorage();
    } else if (res.response && res.response.errorCode === "INVALID_REQUEST_ERROR") {
      _useOlderApi = true;
    } else if (err.zcase(res)) {
      getMainCheckOfMainModeStatus().then(() => resetBrowser(err.redirectUrl));
    }
  };

  const startPolling = err => {
    _globalPoller = setInterval(function pollRequest() {
      if (!_requestIsPending) {
        const promise = err.poll();
        if (promise) {
          promise.then(
            s => {
              handleAuthError(s, err);
            },
            errorRes => {
              handleAuthError(errorRes, err);
            }
          );
        }
      }

      return pollRequest;
    }(), _globalTimeInterval);
  };

  const _initActions = () => {
    if (!_actionsWereSet) {
      $(document).on("click", "#show-tasks-details", function() {
        if (!_tasksListIsShown) {
          $(this).html("Hide details...");
          $("#tasks-list").removeClass("hidden");
        } else {
          $(this).html("Show details...");
          $("#tasks-list").addClass("hidden");
        }
        _tasksListIsShown = !_tasksListIsShown;
      });

      _actionsWereSet = true;
    }
  };

  /**
     * Check the maintenance api to get an error code which tells who is the initiator
     * of maintenance mode. If code is obtained, start polling an api which provides
     * a more detailed status.
     * Any errors caught will restart the checks until maintenance api returns status === false.
     */
  const _initChecks = () => {
    _initActions();
    return getMainCheckOfMainModeStatus()
      .then(checkAuth)
      .then(whichErr)
      .then(setErrText)
      .then(startPolling)
      .catch(() => restart());
  };

  const restart = () => {
    if (_globalPoller) {
      clearInterval(_globalPoller);
      _globalPoller = undefined;
    }

    setTimeout(() => _initChecks(), _globalTimeInterval);
  };

  return _initChecks;
});

define(function(require) {
  const _ = require("underscore");

  //
  // Return a new contrib with nls string values replaced
  // with their mappings in config.nls[language][key], where an
  // nls string value looks like "{{nls!key}}".  The lookup falls
  // back to config.nls.root[key] if config.nls[language][key] is
  // not found.  If there is no such mapping, the value is replaced
  // with a placeholder indicating that the string is missing.
  //
  // Contrib objects are processed recursively, with handling for
  // nested objects and arrays.
  //
  function internationalize(contrib, config, language) {
    const nls = config.nls || {};
    if (Array.isArray(contrib)) {
      return contrib.map(v => internationalize(v, config, language));
    } else if (_.isObject(contrib)) {
      return _.mapObject(contrib, v => internationalize(v, config, language));
    } else if (_.isString(contrib)) {
      const match = /{{nls!(.*)}}/.exec(contrib);
      return match
        ? (nls[language] || {})[match[1]] ||
            (nls.root || {})[match[1]] ||
            `*missing-nls-string*${match[1]}`
        : contrib;
    } else return contrib;
  }

  return internationalize;

  // const testUiConfig = {
  //   nls: {
  //     root: {
  //       hello: "hello",
  //       welcome: "welcome"
  //     },
  //     en: {
  //       hello: "howdy!"
  //     },
  //     es: {
  //       hello: "hola!",
  //       welcome: "bienvenido"
  //     }
  //   },
  //   contributions: {
  //     "cisco.dna.somePlugin/someExtPoint": {
  //       label: "{{nls!welcome}}",
  //       label2: "{{nls!hello}}",
  //       unknown: "{{nls!unknown}}",
  //       rawValue: "rawValue",
  //       foo: {
  //         bar: "{{nls!hello}}",
  //         baz: [
  //           "{{nls!welcome}}",
  //           {
  //             bang: "{{nls!welcome}}",
  //             bzzt: ["{{nls!welcome}}", "{{nls!hello}}", "{{nls!foo}}"]
  //           }
  //         ]
  //       }
  //     }
  //   }
  // };

  // const key = "cisco.dna.somePlugin/someExtPoint";
  // testUiConfig.contributions[key] = internationalize(
  //   testUiConfig.contributions[key],
  //   testUiConfig,
  //   "es"
  // );
  // console.log(JSON.stringify(testUiConfig, null, 2));
});

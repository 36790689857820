define([
  "jquery"
], function($) {
  "use strict";
  return {
    getAllLocations: function(ALOffset, ALLimit) {
      const dfd = new $.Deferred();
      dfd.resolve({});
      return dfd.promise();
    }
  };
});

export default {
  "timermessage1": "오랫동안 비활성 상태였습니다.",
  "timermessage2": "보안을 위해 자동으로 로그아웃됩니다.",
  "timermessage": "{{minutes}}분, {{seconds}}초 남음...",
  "timermessage_showSeconds": "{{seconds}}초 남음...",
  "timeoutwarning": "세션 시간 초과",
  "endsession": "로그아웃",
  "extendsession": "로그인 상태 유지"

};

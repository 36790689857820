define(["jquery"], function($) {

	/**
	 * 
	 *	Responsive.on("tablet", _tablet);
	 *	Responsive.on("mobile", _tablet);
	 *
	 */
	var _mode = "",
		_tablet = '(max-width: 1024px)',
		_mobile = '(max-width: 480px)';

	var changeCallbacks = {
		"tablet": function() {},
		"mobile": function() {},
		"desktop": function() {}
	};

	function _getMode() {
		if (window.matchMedia(_mobile).matches) {
			_mode = "mobile";
		} else if (window.matchMedia(_tablet).matches) {
			_mode = "tablet";
		} else {
			_mode = "desktop";
		}

		return _mode;
	}

	function _resize() {
		var mode = _mode;
		var new_mode = _getMode();
		if (new_mode != mode) {
			changeCallbacks[new_mode]();
		}
	}

	$(window).resize(_resize);
	_getMode();

	return {

		on: function(index, callback) {
			changeCallbacks[index] = callback;
		},

		mode: function() {
			return _getMode();
		}

	};
});
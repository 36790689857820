// Master web worker script that loads worker
// script that comes from a plugin contribution.
// The primary purpose of this is to:
// (1) set up the worker script's environment with requirejs
//     and platform UI libs
// (2) work around same origin limitations of browser
//     web worker implementations so that remote plugins
//     can be handled.
function importModuleDef(event) {
  const moduleDef = event.data
  self.removeEventListener("message", importModuleDef)
  importScripts.apply(self, moduleDef.imports)
  requirejs(moduleDef.js.concat(moduleDef.require), function() {
    self.postMessage("workerLoaded");
  })
}

self.addEventListener("message", importModuleDef);
/*
	Usage is like this:

	Poll(url, interval)
		.until( function(response) { condition is met })
		.then( on complete function )
		.catch( if there was an error )

 */
define(["axios"], function(axios) {

	function Poll(url, interval=1000) {
		// polls the URL until the callback's condition is met
		// then resolves with the response
		var poller = (cb) => new Promise( (resolve, reject) => {
			var checkStatus = function() {
				axios.get(url).then(response => {
					if(cb(response.data.response)) {
						return resolve(response.data.response);
					}
					// otherwise, try again in {interval} time
					setTimeout(checkStatus, interval);
				}).catch(reject);
			}.bind(this);

			checkStatus();
		});

		return {
			until(cb) {
				return poller(cb);
			}
		};
	};

	return Poll;
});
define(["jquery"], function($) {
  function NavMenuItem(className, template) {
    this.button = $(className);
    this.template =
      $(template) || $("<ul class='dropdown-menu dropdown-menu-right settings'></ul>");

    this.button.append(this.template);

    this.events = {};
    this.groups = {};

    this.button.click(e => {
      e.stopPropagation();
      this.emit("open");
      this.open();
    });
  }

  NavMenuItem.prototype.emit = function(evt, val) {
    if (this.events[evt]) {
      this.events[evt].forEach(n => n(val));
    }
  };

  NavMenuItem.prototype.on = function(evt, callback) {
    if (!this.events[evt]) {
      this.events[evt] = [];
    }
    this.events[evt].push(callback);
  };

  NavMenuItem.prototype.open = function() {
    this.button.find(".btn").addClass("_active");
    this.template.addClass("show");
  };

  NavMenuItem.prototype.close = function() {
    this.button.find(".btn").removeClass("_active");
    this.template.removeClass("show");
  };

  NavMenuItem.prototype.append = function({href, title, parent, className}) {
    const item = $(`<li class='${className}'>
      <a href="${href}">
          <span>${title}</span>
      </a>
    </li>`);

    const target = parent ? this.template.find(parent) : this.template;
    target.append(item);
    return item;
  };

  NavMenuItem.prototype.appendMultiple = function(arr) {
    arr.forEach(item => this.append(item));
  };

  return NavMenuItem;
});

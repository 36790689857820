/***************************************************

 Download util
 apicem/utils/download

 Download the file from the url on the same page

 Usage:
 -------------------
 var url = 'http://' + window.location.host + '/certificate-authority/download/default/trustpool';
 var filenameWithExtension = 'ios.pdf';
 download.fromURL(url, filenameWithExtension );

 ****************************************************/
// http://localhost:9000/api/local/logging/file/log-aggregator-log-export-23hg6-49ccd55b-babb-43b0-8b84-450516266268.log
//
//
define([], function() {
  function _fromURL(url, fileNameWithExtension, contentType) {
    const link = document.createElement("a");
    link.href = url;
    if (fileNameWithExtension) {
      link.setAttribute("download", fileNameWithExtension);
    }
    const clickEvent = document.createEvent("MouseEvent");
    clickEvent.initMouseEvent(
      "click",
      true,
      true,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
    link.dispatchEvent(clickEvent);
  }

  return {
    /*
     * Downloads the file from the url on the same page
     * @param url
     *   @param fileNameWithExtension
     */
    fromURL: function(url, fileNameWithExtension, contentType) {
      _fromURL(url, fileNameWithExtension, contentType);
    }
  };
});

export default {
  "timermessage1": "无活动时间过长.",
  "timermessage2": "出于安全原因，您将自动登出。",
  "timermessage": "{{minutes}} 分 {{seconds}} 秒（剩余）...",
  "timermessage_showSeconds": "{{seconds}} 秒（剩余）...",
  "timeoutwarning": "会话超时",
  "endsession": "退出",
  "extendsession": "保持登录状态"

};

(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("amcharts.themes.light"), require("amcharts.responsive"), require("amcharts.export"), require("amcharts.serial"), require("amcharts.pie"), require("amcharts.xy"), require("jqueryui"), require("amcharts"), require("mapbox"), require("mapboxLabel"), require("mapboxMarkerCluster"));
	else if(typeof define === 'function' && define.amd)
		define(["amcharts.themes.light", "amcharts.responsive", "amcharts.export", "amcharts.serial", "amcharts.pie", "amcharts.xy", "jqueryui", "amcharts", "mapbox", "mapboxLabel", "mapboxMarkerCluster"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("amcharts.themes.light"), require("amcharts.responsive"), require("amcharts.export"), require("amcharts.serial"), require("amcharts.pie"), require("amcharts.xy"), require("jqueryui"), require("amcharts"), require("mapbox"), require("mapboxLabel"), require("mapboxMarkerCluster")) : factory(root["amcharts.themes.light"], root["amcharts.responsive"], root["amcharts.export"], root["amcharts.serial"], root["amcharts.pie"], root["amcharts.xy"], root["jqueryui"], root["amcharts"], root["mapbox"], root["mapboxLabel"], root["mapboxMarkerCluster"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, function(__WEBPACK_EXTERNAL_MODULE__199__, __WEBPACK_EXTERNAL_MODULE__200__, __WEBPACK_EXTERNAL_MODULE__201__, __WEBPACK_EXTERNAL_MODULE__351__, __WEBPACK_EXTERNAL_MODULE__468__, __WEBPACK_EXTERNAL_MODULE__469__, __WEBPACK_EXTERNAL_MODULE__606__, __WEBPACK_EXTERNAL_MODULE__861__, __WEBPACK_EXTERNAL_MODULE__1122__, __WEBPACK_EXTERNAL_MODULE__1124__, __WEBPACK_EXTERNAL_MODULE__1125__) {
return 
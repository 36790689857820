const script = document.createElement("script");
script.type = "module";
script.src
  = "/dist/harbor-elements/harbor-elements.esm.js";
const s = document.getElementsByTagName("script")[0];
s && s.parentNode.insertBefore(script, s);

var link = document.createElement('link');
link.rel = 'stylesheet';
link.href = '/dist/harbor-elements/styles/base.css';
document.head && document.head.appendChild(link);
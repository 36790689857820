import axios from "axios"
import throttle from "lodash/throttle";
import broadcastchannel from "./BroadcastChannel"
class SessionTimeout {
   defaultEvents = [
     "load",
     "mousemove",
     "keyup",
     "mousewheel",
     "wheel",
     "click",

   ];
  USER_ACTIVITY_THROTTLER_TIME=500;
  KEEP_ALIVE_INTERVAL = (5 * 60 * 1000) / 60; /*5 minute*/
  idleTimeout;
  idleActivityTimeout;
  timeLeftChangeEventTimeout;
  restTime;
  keepAlivePinged = false;
  throttlerFlag = false;
  isKeepAlive = true;
  keepAliveUrl = "/keepAlive";
  trottledEvent = null;
  constructor(idleTimeout, dialogDisplayLimit) {
    this.idleTimeout = idleTimeout;
    this.dialogDisplayLimit = dialogDisplayLimit
    this.trottledEvent = throttle(this.reset, 3000);
  }
   keepAlive= async() => {
     if (!this.keepAlivePinged) {
       await axios.post(this.keepAliveUrl)
       this.keepAlivePinged = true;
       setTimeout(()=> {
         this.keepAlivePinged = false;
       }, this.KEEP_ALIVE_INTERVAL);
     }
   }
   init = () => {
     for (const event of this.defaultEvents) {
       window.addEventListener(event, this.trottledEvent);
     }
     if (this.iskeepAlive) {
       this.keepAlive()
     }
     this.reset();
   };

   reset = (event) => {
     if (this.idleActivityTimeout !== undefined) {
       window.clearTimeout(this.idleActivityTimeout);
     }
     if (this.timeLeftChangeEventTimeout !== undefined) {
       window.clearTimeout(this.timeLeftChangeEventTimeout);
     }
     if (this.onTimeLeftChange !== undefined) {
       this.timeLeftChangeEventTimeout = window.setTimeout(
         this._onTimeLeftChange,
         this.dialogDisplayLimit
       );
     }

     this.idleActivityTimeout = window.setTimeout(this._onTimeOut, this.idleTimeout);
     this.restTime = Date.now();
     this.updateLastActivity();
   }
  updateLastActivity =() =>{
    let timeIdleTimeout = window.localStorage.getItem("idleTimerLastActivity")
    if (timeIdleTimeout !== null) {
      const elapsedTime = this.restTime - timeIdleTimeout;
      if (this.idleTimeout < elapsedTime) {
        this.triggerEndSession();
        return;
      }
    }
    if (!this.throttlerFlag) {
      window.localStorage.setItem("idleTimerLastActivity", this.restTime)
      this.throttlerFlag = true;
      broadcastchannel.postMessage({
        action: "extendSession"
      });
      setTimeout(()=> {
        this.throttlerFlag = false;
      }, this.USER_ACTIVITY_THROTTLER_TIME);
    }
  }
   dispose = () => {
     for (const event of this.defaultEvents) {
       window.removeEventListener(event, this.trottledEvent);
     }

     if (this.idleActivityTimeout !== undefined) {
       window.clearTimeout(this.idleActivityTimeout);
     }

     if (this.timeLeftChangeEventTimeout !== undefined) {
       window.clearTimeout(this.timeLeftChangeEventTimeout);
     }
   };
  getTimeLeft = () => {
    return this.idleTimeout - (Date.now() - this.restTime) ;
  };
   _onTimeOut = () => {
     this.onTimeOut();
     this.dispose();
   };
   _onTimeLeftChange = () => {
     var timeIdleTimeout = window.localStorage.getItem("idleTimerLastActivity");
     if (this.restTime < timeIdleTimeout) {
       this.reset();
       window.localStorage.setItem("idleTimerLastActivity", timeIdleTimeout)
     } else if (this.isSleepMode()) {
       this.triggerEndSession();
     } else {
       this.onTimeLeftChange(this.getTimeLeft());
     }
   };
  isSleepMode=()=>{
    let timeIdleTimeout = window.localStorage.getItem("idleTimerLastActivity");
    const elapsedTime = Date.now() - timeIdleTimeout;
    return this.idleTimeout < elapsedTime;
  }
  onTimeOut = () => null;
  onTimeLeftChange = (timeLeft) => null
  triggerEndSession=()=> null
}

export default SessionTimeout;
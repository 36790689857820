// AMD loader that resolves promises for loading code-split chunks
define({
  load: function(name, req, onload, config) {
    // req has the same API as require().
    req([name], function(value) {
      if (value && typeof value.then === "function") {
        value.then(onload);
      } else {
        onload(value);
      }
    });
  }
});

define(["core/utils/SupportedBrowser.list"], function(SupportedList) {
  /*
		Copied from
		http://stackoverflow.com/questions/5916900/how-can-you-detect-the-version-of-a-browser

		returns an object {name: "Chrome", version: "55"}
	 */
  function get_browser() {
    var ua = navigator.userAgent,
      tem,
      M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return {name: "IE", version: tem[1] || ""};
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem != null) {
        return {name: "Opera", version: tem[1]};
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1]
    };
  }

  return {
    isSupportedBrowser: function() {
      let currentBrowser = get_browser().name.toLowerCase();
      let supportedBrowsers = new Set(Object.keys(SupportedList));
      return supportedBrowsers.has(currentBrowser);
    },
    isSupportedVersion: function() {
      let currentBrowser = get_browser(),
        browserName = currentBrowser.name.toLowerCase(),
        lowestVersion = SupportedList[browserName];
      return parseInt(currentBrowser.version) >= parseInt(lowestVersion);
    },
    getBrowserInfo: function() {
      let currentBrowser = get_browser();
      return {
        browser: currentBrowser.name,
        version: currentBrowser.version
      };
    }
  };
});

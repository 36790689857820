define([], function() {
  return {
    /**
     * Returns the teplate string with placeholders replaced with values
     * from the given data object.
     *
     * @param template represents the string with handlebars style placeholders
     * @param data object with named values to be substituted in the template
     */
    getFilledTextForHBS: function(template, data) {
      let templateOut = template;
      for (const [key, value] of Object.entries(data)) {
        templateOut = templateOut.replace(new RegExp(`{{ *?${key} *?}}`, 'gi'), value);
      }
      return templateOut;
    }
  };
});

// GV Task using es6 promises syntax.
//
// Usage:
// var task = new task({
// 			url: "",
// 			type: "POST",
// 			interval: 1000,
// 			data: {}
// });
//
// task.start().then( success() ).catch( error() );
define(["core/utils/Poll", "fusion/utils/ErrorHandler", "axios"], function(Poll, ErrorHandler, axios) {
  var URLS = {
    Task: id => `${window.API_URL_PREFIX}/task/${id}`,
    Tree: id => `${window.API_URL_PREFIX}/task/${id}/tree`
  };

  function Task({
    url = "", // api url
    type = "POST", // type of request
    interval = 1000, // interval to poll task
    contentType = "application/json", // you can override contentType
    data = null, // data to send in POST/PUT
    dataType = null, // override default dataType
    stringify = true,
    enctype = null,
    processData = null
  } = {}) {
    var taskId = null;

    // Start the request and chain the results together
    this.start = function() {
      return this.startTask()
        .then(this.waitTillDone.bind(this))
        .then(this.checkStatus.bind(this));
    };

    // Builds a request and does the initial POST/PUT/DELETE
    // resolves with task ID in a promise
    this.startTask = () =>
      new Promise((resolve, reject) => {
        var request = {url, type, contentType};

        if (contentType === false) {
          request.processData = false;
        }

        // If sending data
        if (data) {
          request.data = data;
          request.dataType = "json";
        }

        if (dataType) {
          request.dataType = dataType;
        }

        if (enctype !== null) {
          request.enctype = enctype;
        }

        if (processData !== null) {
          request.processData = processData;
        }

        if (type === "DELETE") {
          request.contentType = "application/json;charset=UTF-8";
        }
        axios({
          method: request.type,
          url: request.url,
          data: request.data
        })
          .then(response => {
            taskId =
              response.data.response.taskId || response.data.response.task_id;
            resolve(taskId);
          })
          .catch(response => {
            reject(new ErrorHandler(response.response ? response.response : response));
          });
      });

    // Will poll the /task API until endTime is set, resolves with
    // task response
    this.waitTillDone = () =>
      new Promise((resolve, reject) => {
        Poll(this.getTaskURL(), interval)
          .until(response => !!response.endTime)
          .then(resolve)
          .catch(response => {
            reject(new ErrorHandler(response.response));
          });
      });

    // Once the task is done, if no error then resolve with the response,
    // otherwise get the tree and reject
    this.checkStatus = function(response) {
      return !response.isError
        ? Promise.resolve(response)
        : new Promise((resolve, reject) => {
          this.getTree().then(reject);
        });
    };

    // Gets a task tree
    this.getTree = () =>
      new Promise((resolve, reject) => {
        axios.get(this.getTreeURL())
          .then(response => {
            resolve(response.data.response);
          })
          .catch(response =>{
            reject(new ErrorHandler(response.response));
          })
      });

    this.getTreeURL = () => URLS.Tree(taskId);
    this.getTaskURL = () => URLS.Task(taskId);

    return this;
  }

  return Task;
});

import { JsonValidator } from "@harbor/json-validator";

const intentsSchema = {
  type: "array",
  items: {
    type: "object",
    required: ["resource", "operationNameSet"],
    properties: {
      resource: { type: "string" },
      operationNameSet: {
        type: "array",
        items: { type: "string" }
      }
    }
  }
};

export async function fetchRbacPermissions() {
  const axios = require("../utils/axios");
  const STORAGE_KEY = "cisco.dna.core.intents";
  try {
    const cachedIntents = JSON.parse(
      localStorage.getItem(STORAGE_KEY)
    );
    const jsonValidator = new JsonValidator();
    if (!jsonValidator.validate(cachedIntents, intentsSchema).valid) {
      throw "Not valid";
    }
  } catch (err) {
    console.warn("Can not fetch cached intents");
  }
  try {
    const r = await axios.get("/api/system/v1/identitymgmt/intents");
    const data = r.data.response.permissions;
    const permissionMap = {};
    for (const { resourceName, operationNameSet } of data) {
      let permissionItem = permissionMap[resourceName];
      if (permissionItem) {
        if (operationNameSet.length > permissionItem.operationNameSet.length) {
          permissionItem.operationNameSet = operationNameSet;
        }
      } else {
        permissionItem = {
          resource: resourceName,
          operationNameSet
        };
      }
      permissionMap[resourceName] = permissionItem;
    }
    const permissions = Object.values(permissionMap);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(permissions));
    return permissions;
  } catch (err) {
    console.error("Failed to load permission list:", err);
    return [];
  }
}

define(["underscore"], function(_) {
  return function(ViewClass, {template, containerClass} = {}) {
    return function createView() {
      const root = document.createElement("div")
      root.style.height = "100%"
      if (containerClass) root.className = containerClass
      if (template) root.innerHTML = template
      let view = null
      let rendered = false
      let tempProps = {}
      return {
        getDOMNode(props) {
          if (rendered) {
            if (_.isFunction(view.updateProps)) {
              view.updateProps(props)
            }
          } else {
            tempProps = props
          }
          return root
        },
        onRender() {
          if (!rendered) {
            view = new ViewClass(Object.assign({}, tempProps))
            root.appendChild(view.el)
            view.render()
            rendered = true
            tempProps = null
          }
        },
        dispose() {
          if (rendered) {
            if (_.isFunction(view.dispose)) {
              view.dispose()
            }
            view.remove()
          }
          view = null
        }
      }
    }
  }
})

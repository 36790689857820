import "./globals.less";
import "@cisco-dna/css/dist/dna.css";

// todo: Work on deprecating the below
import "@cisco-dna/css/dist/bootstrap.css";
import "@cisco-dna/css/dist/dnac-icon.css";
import "@cisco-dna/css/dist/legacy-icons.css";
import "@cisco-dna/css/dist/stegosaurus.css";


import "./vendor/font-awesome-4.7.0/css/font-awesome.min.css";
import "./vendor/jquery-ui/themes/smoothness/jquery-ui.min.css";

// Not sure why node_modules here is needed, but it broke without it
import "../node_modules/apic-em-widgets/dist/common.css";

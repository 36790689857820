define([
    'jquery',
    'backbone',
    'log4javascript'
],
function($, Backbone, Log4Javascript) {
    return {
        viewControllerLogger: function(viewCtrl, logType, moduleName, logObj) {
            if (viewCtrl !== undefined) {

                switch (logType) {
                    case Log4Javascript.Level.ERROR:
                        var errorMessages = viewCtrl.get("errorMessages");
                        errorMessages.push({moduleName: moduleName, logObj: logObj});
                        viewCtrl.set("errorMessages", errorMessages);
                        break;
                    case Log4Javascript.Level.FATAL:
                        var abortMessages = viewCtrl.get("abortMessages");
                        abortMessages.push({moduleName: moduleName, logObj: logObj});
                        viewCtrl.set("abortMessages", abortMessages);
                        break;
                }
            }
        },
        commonControllerLogger: function(commonErrorModelCollection, logType, moduleName, logObj) {
            if (commonErrorModelCollection != undefined) {
                if (logType == Log4Javascript.Level.FATAL) {
                    commonErrorModelCollection.addCommonErrorModel(logType, moduleName, logObj);
                }
            }
        }        
    };
});

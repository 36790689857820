var amdi18n={"__root":{"system":"System","help":"Help","about_dna_center":"About {{productName}}","sign_out":"Sign Out","developer_resource":"Developer Resources","tools":"Tools"},"__en":{"system":"System","help":"Help","about_dna_center":"About {{productName}}","sign_out":"Sign Out","developer_resource":"Developer Resources","tools":"Tools"},"__ja":{"system":"システム","help":"ヘルプ","about_dna_center":"{{productName}}について","sign_out":"サインアウト","developer_resource":"開発者リソース","tools":"ツール"},"__zh":{"system":"系统","help":"帮助","about_dna_center":"关于 {{productName}}","sign_out":"注销","developer_resource":"开发者资源","tools":"有用工具"},"__ko":{"system":"시스템","help":"도움말","about_dna_center":"{{productName}} 정보","sign_out":"로그아웃","developer_resource":"개발자 리소스","tools":"툴"}};amdi18n.init=function(language){
		// get the default language
		if(!language){
			if(window._i18n && window._i18n.locale){
				language = window._i18n.locale;
			}else if(document.documentElement.lang){
				language = document.documentElement.lang;
			}else{
				language = 'root';
			}
		}
		var target = this['__' + language] || this.__root;

		// copy definition to root level
		if (target) {
			for (var name in target) {
				this[name] = target[name];
			}
		}

		// fallback to root
		for(var name in this.__root){
			if(typeof this[name] === 'undefined'){
				this[name] = this.__root[name];
			}
		}
	};amdi18n.init();module.exports=amdi18n;
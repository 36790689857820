define(["i18n!./nls/i18_utils", "underscore"], function(i18nMessageBundle, _) {
  // These just classify what kind of errorData came in,
  const ERROR_TYPES = {
    TEXT: "error:text", // A string containing an error message came in
    RESPONSE_FAIL: "error:response-fail", // The API failed for whatever reason and threw a status code
    TASK: "error:task", // The error came from a task API, that the task failed to complete
    FORBIDDEN: "error:forbidden"
  };

  const STATUS = {
    FORBIDDEN: 403
  };

  const ErrorHandler = function(errorData) {
    var errorMsg = "";
    var result = {};

    if (typeof errorData === "string") {
      // Error message is just a string passed in as an argument
      result = {
        msg: errorData,
        type: ERROR_TYPES.TEXT
      };
    } else if (errorData.data) {
      // Error data comes from a failed jquery request
      result = {
        type: ERROR_TYPES.RESPONSE_FAIL,
        status: errorData.status
      };
      var response = errorData.data;
      if (response.detail) {
        result.msg = response.detail;
      } if(response.message) {
        result.msg = response.message;
      } else {
        // If there's other corner cases, TODO: add them here
        result.msg = i18nMessageBundle.errorhandler_unknown_error;
      }
    } else if (errorData.status === STATUS.FORBIDDEN) {
      result = {
        msg: i18nMessageBundle.errorhandler_invalid_token,
        type: ERROR_TYPES.FORBIDDEN
      };
    } else if (errorData instanceof Array) {
      var msg = errorData[0].failureReason + ": " + errorData[0].progress;
      result = {
        msg: msg,
        type: ERROR_TYPES.TASK
      };
    }

    // Include the type constants above so type checks are easier to do
    //  i.e. if (error.type === error.types.TASK)
    return _.extend(result, {
      types: ERROR_TYPES,
      data: errorData
    });
  };

  return ErrorHandler;
});
